import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { finalize, Observable, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  CHECKOUT_CONFIG,
  CLIENT,
  CONFIRM_PURCHASE,
  PAY_FOR_PURCHASE,
  START_PURCHASE
} from '../common/data/consts';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {}

  public startPurchase(
    items: PurchasableItems[],
    customTags?: object
  ): Observable<Request> {
    return this.httpClient
      .post<Request>(`${environment.baseApi}/${CLIENT}/${START_PURCHASE}`, {
        Items: items
      })
      .pipe(switchMap((res) => this.payForPurchase({ ...res, customTags })));
  }

  private payForPurchase({ data }: any): Observable<Request> {
    const { Currency, ProviderName } = data.PaymentOptions[0];
    return this.httpClient
      .post<Request>(`${environment.baseApi}/${CLIENT}/${PAY_FOR_PURCHASE}`, {
        Currency,
        OrderId: data.OrderId,
        ProviderName,
        customTags: data.customTags
      })
      .pipe(
        tap(({ data }: any) => {
          this.sessionStorageService.setItem(CHECKOUT_CONFIG, data.OrderId);
          window.open(data.PurchaseConfirmationPageURL, '_self');
        })
      );
  }

  public confirmPurchase(orderId: string): Observable<Request> {
    return this.httpClient
      .post<Request>(`${environment.baseApi}/${CLIENT}/${CONFIRM_PURCHASE}`, {
        OrderId: orderId
      })
      .pipe(finalize(() => this.sessionStorageService.clearSessionStorage()));
  }
}

interface PurchasableItems {
  Annotation: string;
  ItemId: string;
  Quantity: number;
}
