import { NgModule } from '@angular/core';
import { SignInComponent } from './sign-in/sign-in.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { PickRoleTypeComponent } from './pick-role-type/pick-role-type.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AccessRequestComponent } from './access-request/access-request.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetConfirmationComponent } from './reset-confirmation/reset-confirmation.component';
import { RoleTypeComponent } from './components/role-type/role-type.component';
import { RegistrationFormComponent } from './components/registration-form/registration-form.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentWrapperComponent } from './components/content-wrapper/content-wrapper.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingButtonComponent } from '../shared/loading-button/loading-button.component';
import { FormErrorTransformPipe } from '../common/pipes/form-error-transform.pipe';
import { ShowPasswordButtonComponent } from '../shared/show-password/show-password-button.component';
import { ChoosePlanComponent } from './choose-plan/choose-plan.component';
import { UserPlanComponent } from './components/user-plan/user-plan.component';
import { UserTypeTransformPipe } from '../common/pipes/user-type-transform.pipe';
import { VerificationComponent } from './verification/verification.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { LoaderComponent } from '../shared/loader/loader.component';

@NgModule({
  declarations: [
    //Components
    SignUpComponent,
    SignInComponent,
    ChoosePlanComponent,
    WelcomePageComponent,
    PickRoleTypeComponent,
    AccessRequestComponent,
    ResetPasswordComponent,
    RecoverPasswordComponent,
    ResetConfirmationComponent,
    RoleTypeComponent,
    RegistrationFormComponent,
    VerificationComponent,
    ContentWrapperComponent,
    UserPlanComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    PaymentStatusComponent
  ],
  imports: [
    //Pipes
    FormErrorTransformPipe,
    UserTypeTransformPipe,
    //Components
    HeaderComponent,
    LoadingButtonComponent,
    ShowPasswordButtonComponent,
    //Modules
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    LoaderComponent
  ]
})
export class PagesModule {}
