<div
  class="relative px-8 py-8 h-full bg-contain bg-no-repeat bg-center bg-[url('assets/images/bg_fed.png')]"
>
  <div class="w-[60vw] m-auto flex items-center justify-center">
    <img alt="Beat The Fed" src="assets/images/BeatTheFed.png" />
  </div>

  <div
    class="text-center font-bold text-2xl fixed w-full m-auto left-0 text-neutral-500"
    style="bottom: 25%"
  >
    <div>A Serious Game</div>
    <div>About The Federal Reserve</div>
  </div>

  <div class="flex justify-center items-center fixed bottom-8 left-0 w-full">
    <button
      class="py-4 px-8 w-56 bg-gradient-to-t from-sky-600 to-blue-400 font-bold text-white"
      routerLink="/sign-in"
      routerLinkActive="active"
    >
      Log in
    </button>
    <button
      class="py-4 px-8 w-56 bg-gradient-to-t from-orange-600 to-amber-600 text-white ml-4"
      routerLink="/pick-role"
      routerLinkActive="active"
    >
      Sign up
    </button>
  </div>
</div>
