import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, map, Observable, tap } from 'rxjs';
import { Request } from '../common/interfaces/request';
import { environment } from '../../environments/environment';
import {
  ADMIN,
  GET_CATALOG_ITEMS,
  GET_USER_INVENTORY,
  PLAYFABID
} from '../common/data/consts';
import { LocalStorageService } from './local-storage.service';
import {
  academicSeatId,
  choosePlans,
  classSeatsId,
  individualSeatId,
  stocktrakSeatId
} from '../common/data/choose-plans';
import { convertRMToUsd } from '../common/utils';
import { CatalogItem } from '../common/interfaces/catalog-item';
import { Plan } from '../common/interfaces/plan';
import { UserPlan } from '../common/enums/user-plan';
import { UserRole } from '../common/enums/user-role';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {
  private userPlan: {
    [UserPlan.Academic]: Plan;
    [UserPlan.Individual]: Plan;
  } = choosePlans;
  public studentSeatPrice: number | null = null;
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  public hasSeats(role: UserRole): Observable<boolean> {
    return this.getUserInventory().pipe(
      map(({ data }) =>
        data.Inventory.some((item) => {
          if (role === UserRole.INSTRUCTOR) {
            return [academicSeatId, stocktrakSeatId].includes(item.ItemId);
          } else {
            return item.ItemId === individualSeatId;
          }
        })
      )
    );
  }

  private getUserInventory(): Observable<
    Request<{
      Inventory: {
        ItemId: string;
        RemainingUses?: number;
      }[];
    }>
  > {
    const playFabId = this.localStorage.getItem(PLAYFABID);
    if (!playFabId) return EMPTY;
    return this.httpClient.post<
      Request<{ Inventory: { ItemId: string; Expiration: string }[] }>
    >(`${environment.baseApi}/${ADMIN}/${GET_USER_INVENTORY}`, {
      PlayFabId: playFabId
    });
  }

  public getUserSeatsCount(): Observable<number> {
    return this.getUserInventory().pipe(
      map(({ data }) => {
        const seats = data.Inventory.filter((item) =>
          [classSeatsId, academicSeatId, stocktrakSeatId].includes(item.ItemId)
        ).map((seat) => seat.RemainingUses);
        return seats?.length
          ? seats.reduce((a, b) => (a || 0) + (b || 0), 0) || 0
          : 0;
      })
    );
  }

  public checkStockTraktype(): Observable<boolean> {
    return this.getUserInventory().pipe(
      map(({ data }) => {
        const stocktrakSeat = data.Inventory.find(
          (element) => element.ItemId === stocktrakSeatId
        );
        return !!stocktrakSeat;
      })
    );
  }

  public getCatalogItems(): Observable<{
    [UserPlan.Academic]: Plan;
    [UserPlan.Individual]: Plan;
  }> {
    return this.httpClient
      .post<Request<{ Catalog: CatalogItem[] }>>(
        `${environment.baseApi}/${ADMIN}/${GET_CATALOG_ITEMS}`,
        {}
      )
      .pipe(
        map(({ data }) =>
          data.Catalog.map((item) => ({
            ItemId: item.ItemId,
            VirtualCurrencyPrices: item.VirtualCurrencyPrices
          }))
        ),
        tap((res) => {
          this.userPlan[UserPlan.Academic].price = this.getCatalogItemPrice(
            academicSeatId,
            res
          );
          this.userPlan[UserPlan.Individual].price = this.getCatalogItemPrice(
            individualSeatId,
            res
          );
          this.studentSeatPrice = this.getCatalogItemPrice(classSeatsId, res);
        }),
        map(() => this.userPlan)
      );
  }

  private getCatalogItemPrice(
    itemId: string,
    catalog: CatalogItem[]
  ): number | null {
    const prices = catalog.find(
      (item: CatalogItem) => item.ItemId === itemId
    )?.VirtualCurrencyPrices;
    if (prices?.RM) {
      return convertRMToUsd(prices.RM);
    }
    return null;
  }
}
