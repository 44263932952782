import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { utils } from '../common/utils';
import { UserRole } from '../common/enums/user-role';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {}

  canActivateChild(): boolean | Promise<boolean> {
    const role = this.userService.getUserRole();
    const accountId =
      role === UserRole.INSTRUCTOR ? this.userService.getUser()?.accountId : '';
    if (this.authService.isAuthenticated() && role) {
      this.router.navigateByUrl(utils(role, accountId));
      return false;
    }
    return true;
  }
}
