import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { SignInForm } from '../../common/interfaces/form';
import { catchError, EMPTY } from 'rxjs';
import { filterRequestCode } from '../../common/utils';

@UntilDestroy()
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent {
  public loading = false;
  public passwordType = 'password';
  public requestErrorMessage?: string;
  public formGroup: FormGroup<SignInForm>;

  constructor(
    formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.formGroup = formBuilder.group<SignInForm>({
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true
      }),
      password: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true
      })
    });
  }

  public handleSignIn(): void {
    if (this.formGroup.valid) {
      this.loading = true;
      this.authService
        .signIn(this.formGroup.getRawValue())
        .pipe(
          catchError((e) => {
            this.requestErrorMessage =
              e?.error?.errorMessage || e?.errorMessage || 'Unknown error';
            this.loading = false;
            return EMPTY;
          }),
          filterRequestCode,
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  public handleForgot(): void {
    this.router.navigateByUrl('/recover-password');
  }
}
