import { Component, OnInit } from '@angular/core';
import { UserPlan } from '../../common/enums/user-plan';
import { Plan } from '../../common/interfaces/plan';
import { choosePlans } from '../../common/data/choose-plans';
import { Router } from '@angular/router';
import { UserType } from '../../common/enums/user-type';
import { InventoryService } from '../../services/inventory.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
@UntilDestroy()
export class ChoosePlanComponent implements OnInit {
  public UserPlan = UserPlan;
  public UserType = UserType;
  public userPlan: {
    [UserPlan.Academic]: Plan;
    [UserPlan.Individual]: Plan;
  } = choosePlans;
  public studentSeatPrice?: number | null;

  constructor(
    private router: Router,
    private inventoryService: InventoryService
  ) {}

  ngOnInit(): void {
    this.fetchCatalogItems();
  }

  public handleSignUpUser(userType: UserType): void {
    this.router.navigate(['/sign-up'], {
      queryParams: {
        userType
      }
    });
  }

  private fetchCatalogItems() {
    this.inventoryService
      .getCatalogItems()
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.userPlan = res;
        this.studentSeatPrice = this.inventoryService.studentSeatPrice;
      });
  }
}
