import { Pipe, PipeTransform } from '@angular/core';
import { formErrorMessages } from '../data/formErrorMessages';
import {
  RecoverPasswordForm,
  SignInForm,
  SignUpForm
} from '../interfaces/form';

@Pipe({
  name: 'formErrorTransform',
  standalone: true,
  pure: false
})
export class FormErrorTransformPipe implements PipeTransform {
  transform(controls: SignUpForm | SignInForm | RecoverPasswordForm): string {
    for (let name in controls) {
      const control =
        controls[name as keyof (SignUpForm | SignInForm | RecoverPasswordForm)];
      if (control?.errors && control.dirty) {
        return formErrorMessages[name]?.[!control.value ? 0 : 1] ?? '';
      }
    }
    return '';
  }
}
