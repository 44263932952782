<div
  class="px-8 py-3 relative top-0 bg-white h-[90px] border-b border-b-gray-400"
>
  <div class="flex justify-between items-center">
    <div [class.m-auto]="!userEmail">
      <img
        alt="Beat The Fed"
        [class.m-auto]="!userEmail"
        class="w-56 mt-1 cursor-pointer"
        src="../../../../assets/images/BeatTheFed.png"
        routerLink="/"
      />
    </div>

    <div
      *ngIf="userEmail"
      class="flex items-center gap-16 justify-end relative"
      appClickOutside
      (clickOutside)="isOpen = false"
    >
      <button
        *ngIf="!isGameMode && canPlay"
        class="py-3 px-4 w-40 bg-gradient-to-t from-sky-600 to-blue-400 text-md text-white"
        routerLink="/dashboard/game"
      >
        Launch Game!
      </button>
      <div
        class="flex items-center gap-2.5 bg-[#F2F2F2] py-[3px] px-[5px] shadow-[0px_1px_0px_0px_rgba(0,0,0,0.25)] rounded-lg border-[#E4E4E4] border-[1px] cursor-pointer"
        (click)="toggleAccount()"
      >
        <img
          alt="Beat The Fed"
          class="w-9 h-9"
          src="../../../../assets/images/Avatar.png"
        />
        <div class="flex flex-col justify-start">
          <p
            class="text-sm text-ellipsis overflow-hidden max-w-[300px] text-[#808080]"
          >
            {{ userEmail }}
          </p>
        </div>
      </div>
      <div
        class="flex flex-col gap-1 absolute top-3 right-3 py-[40px] px-[40px] w-[320px] bg-[#E4ECF0] rounded-[14px] z-10"
        *ngIf="isOpen"
      >
        <div class="flex justify-end">
          <div
            class="flex absolute right-[10px] top-[10px] justify-center bg-amber-50 rounded-full p-2 border-[#EAEAEA] border-[1px] cursor-pointer"
            (click)="toggleAccount()"
          >
            <img src="assets/images/icons/closeIcon.svg" alt="close_icon" />
          </div>
        </div>

        <div class="flex flex-col items-center mb-6">
          <img
            src="../../../../assets/images/AvatarLg.svg"
            class="w-16 h-16 mb-4"
            alt="Beat The Fed"
          />
          <p
            class="text-[18px] leading-6 text-ellipsis overflow-hidden max-w-[300px] text-[#808080]"
          >
            {{ userEmail }}
          </p>
        </div>
        <div class="flex flex-col items-center gap-4">
          <button
            *ngIf="canChangePlan"
            class="rounded-lg bg-[#F2F2F2] border-[1px] border-[#E4E4E4] py-2.5 max-w-[231px] w-full"
            (click)="changeAccountType()"
          >
            <span
              class="text-[16px] leading-5 text-ellipsis overflow-hidden max-w-[300px] text-[#808080]"
              >Change Account Type</span
            >
          </button>
          <button
            class="rounded-lg bg-[#F2F2F2] border-[1px] border-[#E4E4E4] py-2.5 max-w-[231px] w-full"
            (click)="logOut()"
          >
            <span
              class="text-[16px] leading-5 text-ellipsis overflow-hidden max-w-[300px] text-[#808080]"
              >Log Out</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
