<div class="w-full px-10 py-5">
  <div>
    <span
      [ngClass]="{
        'text-amber-500': userType === UserType.Teacher,
        'text-sky-500': userType === UserType.Student,
        'text-teal-500':
          userType === UserType.Individual || userType === UserType.DemoPlayer
      }"
      class="text-2xl text-amber-500"
    >
      {{ isStockTrak ? displayTypes.StockTrak : displayTypes[userType] }}
    </span>
    <span class="text-2xl ml-4 text-gray-600">Sign up</span>
  </div>

  <p [ngSwitch]="userType" class="text-gray-500" *ngIf="!isStockTrak">
    <ng-template [ngSwitchCase]="UserType.Teacher">
      Set up your account below.
    </ng-template>
    <ng-template [ngSwitchCase]="UserType.Student">
      Create your Beat The Fed account to connect to your Instructor's classes!
    </ng-template>
    <ng-template [ngSwitchCase]="UserType.Individual">
      Create your Beat The Fed account!
    </ng-template>
    <ng-template [ngSwitchCase]="UserType.DemoPlayer">
      In order to play the Demo, you need to create your free Beat The Fed
      account and verify you're not robot.
    </ng-template>
  </p>

  <p class="text-gray-500" *ngIf="isStockTrak">Set up your account below.</p>

  <div class="flex mb-10">
    <span>Already have an account ?</span>
    <a class="text-sky-400 underline ml-2" routerLink="/sign-in">Log in</a>
  </div>

  <form (submit)="handleSubmitForm()" [formGroup]="formGroup" class="pr-16">
    <div class="pr-40">
      <div
        class="flex gap-6 mb-6"
        *ngIf="
          ![UserType.Individual, UserType.DemoPlayer].includes($any(userType))
        "
      >
        <div
          [class.error]="
            !formGroup.get('firstName')?.valid &&
            formGroup.get('firstName')?.touched
          "
          class="w-full"
        >
          <div>First Name</div>
          <input
            class="border rounded border-gray-400 p-2 w-full"
            formControlName="firstName"
            type="text"
          />
        </div>

        <div
          [class.error]="
            !formGroup.get('lastName')?.valid &&
            formGroup.get('lastName')?.touched
          "
          class="w-full"
        >
          <div>Last Name</div>
          <input
            class="border rounded border-gray-400 p-2 w-full"
            formControlName="lastName"
            type="text"
          />
        </div>
      </div>

      <div
        [class.error]="
          !formGroup.get('email')?.valid && formGroup.get('email')?.touched
        "
        class="mb-6"
      >
        <div>
          {{ userType === UserType.Student ? 'Your School ' : '' }}Email
        </div>
        <input
          class="border rounded border-gray-400 p-2 w-full"
          formControlName="email"
          type="email"
        />
      </div>

      <div
        [class.error]="
          !formGroup.get('password')?.valid &&
          formGroup.get('password')?.touched
        "
        class="mb-6"
      >
        <div class="flex items-center justify-between">
          <div>Password</div>
          <app-show-password-button
            (passwordTypeChange$)="passwordType = $event"
            *ngIf="formGroup.get('password')?.value"
          ></app-show-password-button>
        </div>
        <input
          [type]="passwordType"
          autocomplete="password"
          class="border rounded border-gray-400 p-2 w-full"
          formControlName="password"
        />
      </div>

      <ul class="mb-4 grid grid-cols-2">
        <li
          *ngFor="let rule of passwordRules"
          class="list-disc list-inside mb-2 text-xs"
        >
          {{ rule }}
        </li>
      </ul>

      <div
        [class.error]="
          !formGroup.get('organization')?.valid &&
          formGroup.get('organization')?.touched
        "
        class="mb-8 h-20 min-h-full"
      >
        <ng-container *ngIf="userType === UserType.Teacher">
          <div>{{ isStockTrak ? 'School/Organization' : 'Organization' }}</div>
          <input
            class="border rounded border-gray-400 p-2 w-full"
            formControlName="organization"
            type="text"
          />
        </ng-container>
        <div
          *ngIf="UserType.Student === userType"
          [class.error]="
            !formGroup.get('classCode')?.valid &&
            formGroup.get('classCode')?.touched
          "
          class="w-full"
        >
          <div>
            Enter Unique Code
            {{ UserType.Student === userType ? '(from your Instructor)' : '' }}
          </div>
          <input
            class="border rounded border-gray-400 p-2 w-full"
            formControlName="classCode"
            type="text"
          />
        </div>
      </div>
    </div>
    <div>
      <div class="flex items-center justify-center">
        <app-loading-button
          [disabled]="!formGroup.valid || loading"
          [loading]="loading"
          [text]="
            userType === UserType.Student ? 'Launch Game!' : 'Create Account'
          "
        ></app-loading-button>
      </div>
      <div
        [class.invisible]="
          !(requestErrorMessage || (formGroup.controls | formErrorTransform))
        "
        style="word-break: break-all"
        class="py-3 px-4 w-96 mt-4 ml-auto mr-auto border-orange-300 border-2 text-orange-400 text-x text-center flex items-center justify-center"
      >
        {{ requestErrorMessage || (formGroup.controls | formErrorTransform) }}
      </div>
    </div>
    <div *ngIf="userType === UserType.Teacher && !isStockTrak" class="mt-4">
      Once approved, you’ll get a
      <span class="text-orange-400 font-bold">Class Code</span> to distribute to
      your students.
    </div>

    <div class="mt-4">
      <span>By clicking "Create Account" button, you agree to</span>
      <a class="text-sky-400 cursor-pointer" routerLink="/terms-of-service">
        Terms of Use</a
      >
      <span> and</span>
      <a class="text-sky-400 cursor-pointer" routerLink="/privacy-policy">
        Privacy Policy</a
      >
    </div>
  </form>
</div>
