import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { PickRoleTypeComponent } from './pages/pick-role-type/pick-role-type.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AccessRequestComponent } from './pages/access-request/access-request.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { ResetConfirmationComponent } from './pages/reset-confirmation/reset-confirmation.component';
import { AuthGuard } from './guards/auth.guard';
import { UnAuthGuard } from './guards/un-auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChoosePlanComponent } from './pages/choose-plan/choose-plan.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [UnAuthGuard],
    children: [
      {
        path: '',
        component: WelcomePageComponent
      },
      {
        path: 'pick-role',
        component: PickRoleTypeComponent
      },
      {
        path: 'choose-plan',
        component: ChoosePlanComponent
      },
      {
        path: 'sign-up',
        component: SignUpComponent
      },
      {
        path: 'sign-in',
        component: SignInComponent
      },
      {
        path: 'access-requested',
        component: AccessRequestComponent
      },
      {
        path: 'verification',
        component: VerificationComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'recover-password',
        component: RecoverPasswordComponent
      },
      {
        path: 'password-confirmation',
        component: ResetConfirmationComponent
      }
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-service',
    component: TermsOfUseComponent
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canLoad: [AuthGuard],
    component: DashboardComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
