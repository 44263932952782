import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { RecoverPasswordForm } from '../../common/interfaces/form';
import { comparePasswords, validatePassword } from '../../common/validators';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordRules } from 'src/app/common/data/formErrorMessages';
import { catchError, EMPTY } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterRequestCode } from '../../common/utils';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public formGroup!: FormGroup<RecoverPasswordForm>;
  public requestErrorMessage?: string;
  public passwordType = 'password';
  public passwordRules = passwordRules;
  public userToken!: string;
  public loading = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.init();
  }

  public handleReset(): void {
    if (this.formGroup.valid) {
      this.loading = true;
      this.authService
        .resetPassword({
          token: this.userToken,
          password: this.formGroup.getRawValue().password
        })
        .pipe(
          catchError((e) => {
            this.requestErrorMessage = e?.error?.errorMessage ?? '';
            this.loading = false;
            return EMPTY;
          }),
          filterRequestCode,
          untilDestroyed(this)
        )
        .subscribe(() => this.router.navigateByUrl('/password-confirmation'));
    }
  }

  private init(): void {
    this.userToken = this.activatedRoute.snapshot.queryParams['token'];
    this.formGroup = this.formBuilder.group<RecoverPasswordForm>(
      {
        password: new FormControl('', {
          validators: [Validators.required, validatePassword],
          nonNullable: true
        }),
        confirmPassword: new FormControl('', {
          validators: [Validators.required, validatePassword],
          nonNullable: true
        })
      },
      { validators: comparePasswords }
    );
  }
}
