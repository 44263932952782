import { Injectable } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { Student } from '../common/interfaces/student';
import { PlayerInfo, UserData } from '../common/interfaces/user';
import { UserType } from '../common/enums/user-type';
import { UserRole } from '../common/enums/user-role';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private fileSaveOpts = {
    types: [
      {
        accept: { 'text/csv': ['.csv'] }
      }
    ]
  };

  constructor(private papa: Papa) {}

  public async downloadAllData(
    data: UserData[],
    role: UserRole
  ): Promise<void> {
    const csvData = this.fetchAllData(data, role);
    const file = new Blob([this.papa.unparse(csvData)], {
      type: 'text/csv;charset=utf-8;'
    });

    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${role}.csv`; // Customize filename if needed
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  public async downloadLocationData(
    data: PlayerInfo[],
    role: UserRole
  ): Promise<void> {
    const csvData = this.fetchLocationData(data, role);
    const file = new Blob([this.papa.unparse(csvData)], {
      type: 'text/csv;charset=utf-8;'
    });

    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${role}_location.csv`; // Customize filename if needed
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  public async download(
    data: Student[] | UserData[],
    type: UserType
  ): Promise<void> {
    const csvData = this.fetchData(data, type);
    const file = new Blob([this.papa.unparse(csvData)], {
      type: 'text/csv;charset=utf-8;'
    });
    const newHandle = await (window as any).showSaveFilePicker(
      this.fileSaveOpts
    );
    const writableStream = await newHandle.createWritable();
    await writableStream.write(file);
    await writableStream.close();
  }

  private fetchAllData(data: UserData[], role: UserRole) {
    return data.map((item: UserData) => {
      return {
        'First Name': item.firstName,
        'Last Name': item.lastName,
        email: item.email,
        username: item.username,
        School: item.organization,
        'Sign up date': item.Created,
        ...(role === UserRole.STUDENT
          ? {
              'Episode Score': item.episode_score,
              'Vip cash': item.vip_cash,
              'Episodes cash': item.episodes_cash,
              'episodes history key': item.episodes_history_key
            }
          : {})
      };
    });
  }

  private fetchLocationData(data: PlayerInfo[], role: UserRole) {
    return data.map((item: PlayerInfo) => {
      return {
        Created: item.Created ? new Date(item.Created).toDateString() : '-',
        DisplayName: item.DisplayName ?? '-',
        ...(role === UserRole.INSTRUCTOR
          ? { Email: item.ContactEmailAddresses?.[0]?.EmailAddress ?? '-' }
          : {}),
        LastLogin: item.LastLogin
          ? new Date(item.LastLogin).toDateString()
          : '-',
        City: item.Locations?.[0]?.City ?? '-',
        CountryCode: item.Locations?.[0]?.CountryCode ?? '-'
      };
    });
  }

  private fetchData(data: Student[] | UserData[], type: UserType) {
    return data.map((item: Student | UserData) => {
      switch (type) {
        case UserType.Student:
          return {
            'First Name': item.firstName,
            'Last Name': item.lastName,
            email: item.email,
            username: item.username,
            'Class Name': item.className,
            'Episode Score': item.episode_score,
            'Grade Level': item.grade_level,
            'Account Id': (item as Student).accountId,
            'Quizzes Answer': (item as Student).quizzesAnswer,
            'Quizzes Percent': (item as Student).quizzesPercent
          };
        case UserType.Teacher:
          return {
            'First Name': (item as UserData).firstName?.Value,
            'Last Name': (item as UserData).lastName?.Value,
            email: (item as UserData).email?.Value,
            username: (item as UserData).username?.Value,
            Role: (item as UserData).roleId?.Value,
            organization: (item as UserData).organization?.Value,
            'Sign Up Date': (item as UserData)?.firstName?.LastUpdated
              ? new Date(
                  (item as UserData).firstName.LastUpdated as string
                ).toDateString()
              : ''
          };
        default:
          return {};
      }
    });
  }
}
