<div class="grid grid-cols-3 sign-up-wrapper h-full">
  <div class="w-full col-span-1">
    <div
      [ngClass]="{
        'bg-[url(assets/images/bg_sidebar_signup_teacher.png)]':
          userType === UserType.Teacher || isStockTrak,
        'bg-[url(assets/images/bg_sidebar_signup_student.png)]':
          userType === UserType.Student,
        'bg-[url(assets/images/bg_sidebar_signup_individual.png)]':
          userType === UserType.Individual || userType === UserType.DemoPlayer
      }"
      class="bg-cover bg-no-repeat bg-center h-full relative"
    >
      <div
        class="flex justify-center items-center text-white absolute bottom-8 left-0 w-full"
      >
        <button
          *ngIf="userType | userTypeTransform as type"
          (click)="handleChangeUserType(type)"
          class="flex justify-center items-center text-white"
        >
          Wait I'm a
          <span class="underline ml-1">{{ type }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="col-span-2">
    <app-registration-form
      [loading]="loading"
      [isStockTrak]="
        activatedRoute.snapshot.queryParams['userType'] === 'StockTrak'
      "
      (submitForm$)="signUp($event)"
      [requestErrorMessage]="requestErrorMessage"
      [userType]="userType"
    ></app-registration-form>
  </div>
  <app-loader *ngIf="loading"></app-loader>
</div>
