import { Component, HostListener, inject, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { catchError, filter, switchMap, tap } from 'rxjs';
import { UserRole } from '../common/enums/user-role';
import { PlayerInfo, UserData } from '../common/interfaces/user';
import { FileService } from '../services/file.service';
import { PopupService } from '../services/popup.service';
import { InformationPopupComponent } from '../shared/info-popup/information-popup.component';
import { ErrorPopupComponent } from '../shared/error-popup/error-popup.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  private isLoading: boolean = false;
  private file: FileService = inject(FileService);
  public adminService: AdminService = inject(AdminService);
  private popupService: PopupService = inject(PopupService);
  public progress: number = 0;

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent): void {
    if (this.isLoading) {
      event.preventDefault();
      event.returnValue = 'If you close the tab, the action will be canceled.';
    }
  }

  ngOnInit(): void {
    this.subscribeToCSVExport();
    this.subscribeToRemovePlayer();
    this.subscribeToLocationCSVExport();
  }

  public downloadUserDataCSV(
    data: UserData[] | undefined,
    role: UserRole
  ): void {
    if (!data) return;
    this.file.downloadAllData(data, role);
  }

  public downloadUserLocationCSV(
    data: PlayerInfo[] | undefined,
    role: UserRole
  ): void {
    if (!data) return;
    this.file.downloadLocationData(data, role);
  }

  private showCSVExportInfoPopup() {
    return this.popupService.openModal(
      {
        title: 'Export in progress...',
        body: 'Please do not close this browser tab. The export process may take a few minutes. Your CSV file will be downloaded automatically once it is ready.'
      },
      InformationPopupComponent
    );
  }

  private showRemoveInfoPopup() {
    return this.popupService.openModal(
      {
        title: 'Remove in progress...',
        body: 'Please do not close this browser tab. The remove process may take a few minutes.'
      },
      InformationPopupComponent
    );
  }

  private showRemoveInProgressPopup() {
    return this.popupService.openModal(
      {
        title: 'Remove in progress...',
        body: 'A remove is already in progress. Please wait for it to finish before starting a new one.'
      },
      InformationPopupComponent
    );
  }

  private showInProgressPopup() {
    return this.popupService.openModal(
      {
        title: 'Export in progress...',
        body: 'An export is already in progress. Please wait for it to finish before starting a new one.'
      },
      InformationPopupComponent
    );
  }

  private showActionStatusPopup(
    title: string = 'Error',
    body: string = 'Something went wrong, please try again later'
  ) {
    return this.popupService.openModal(
      {
        title,
        body
      },
      ErrorPopupComponent
    );
  }

  private subscribeToCSVExport(): void {
    let userRole: UserRole;
    this.adminService.CSVDownload$.pipe(
      filter(() => {
        if (this.isLoading) {
          this.showInProgressPopup();
        }
        return !this.isLoading;
      }),
      tap(() => {
        this.isLoading = true;
        this.showCSVExportInfoPopup();
      }),
      switchMap((role: UserRole) => {
        userRole = role;
        return this.adminService.listMembership(role);
      }),
      catchError((err) => {
        this.isLoading = false;
        this.adminService.progressPercentage = 0;
        this.popupService.closeModal();
        this.showActionStatusPopup();
        throw err;
      })
    ).subscribe((userData: UserData[]) => {
      this.adminService.progressPercentage = 0;
      this.isLoading = false;
      this.downloadUserDataCSV(userData, userRole);
      this.popupService.closeModal();
    });
  }

  private subscribeToLocationCSVExport(): void {
    let userRole: UserRole;
    this.adminService.locationCSVDownload$
      .pipe(
        filter(() => {
          if (this.isLoading) {
            this.showInProgressPopup();
          }
          return !this.isLoading;
        }),
        tap(() => {
          this.isLoading = true;
          this.showCSVExportInfoPopup();
        }),
        switchMap((role: UserRole) => {
          userRole = role;
          return this.adminService.listMembershipInfo(role);
        }),
        catchError((err) => {
          this.isLoading = false;
          this.adminService.progressPercentage = 0;
          this.popupService.closeModal();
          this.showActionStatusPopup();
          throw err;
        })
      )
      .subscribe((userData: PlayerInfo[]) => {
        this.adminService.progressPercentage = 0;
        this.isLoading = false;
        this.downloadUserLocationCSV(userData, userRole);
        this.popupService.closeModal();
      });
  }

  private subscribeToRemovePlayer(): void {
    let userRole: UserRole;
    this.adminService.removePlayer$
      .pipe(
        filter(() => {
          if (this.isLoading) {
            this.showRemoveInProgressPopup();
          }
          return !this.isLoading;
        }),
        tap(() => {
          this.isLoading = true;
          this.showRemoveInfoPopup();
        }),
        switchMap((role: UserRole) => {
          userRole = role;
          return this.adminService.removeMasterPlayerAccount(role);
        }),
        catchError((err) => {
          this.isLoading = false;
          this.popupService.closeModal();
          this.showActionStatusPopup();
          this.adminService.progressPercentage = 0;
          throw err;
        })
      )
      .subscribe(() => {
        this.isLoading = false;
        this.adminService.progressPercentage = 0;
        this.showActionStatusPopup(
          'Success',
          `The ${userRole} have been successfully removed.`
        );
      });
  }
}
