import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BasePopUpComponent } from '../base-pop-up/base-pop-up.component';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
  standalone: true
})
export class ErrorPopupComponent extends BasePopUpComponent<any> {
  @Input() public override data: any;
  @Output() confirmEvent = new EventEmitter();

  constructor() {
    super();
  }
}
