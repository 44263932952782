import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserType } from '../../../common/enums/user-type';
import { passwordRules } from '../../../common/data/formErrorMessages';
import { SignUpFormData } from '../../../common/interfaces/user';
import { SignUpForm } from '../../../common/interfaces/form';
import { validatePassword } from '../../../common/validators';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss']
})
export class RegistrationFormComponent {
  @Input() public requestErrorMessage?: string;
  @Input() public loading?: boolean;
  @Input() public isStockTrak?: boolean;
  @Output() public submitForm$: EventEmitter<SignUpFormData> =
    new EventEmitter<SignUpFormData>();

  public formGroup!: FormGroup<SignUpForm>;
  public UserType = UserType;
  public passwordType = 'password';
  public passwordRules = passwordRules;

  constructor(
    private formBuilder: FormBuilder,
    activatedRoute: ActivatedRoute
  ) {
    this.userType = activatedRoute.snapshot.queryParams['userType'];
  }

  private _userType!: UserType;
  public displayTypes = {
    [UserType.DemoPlayer]: 'Demo Player',
    [UserType.Student]: 'Student',
    [UserType.Teacher]: 'Instructor',
    [UserType.Individual]: 'Individual',
    StockTrak: 'Academic'
  };

  public get userType(): UserType {
    return this._userType;
  }

  @Input()
  public set userType(userType: UserType) {
    this._userType = userType;
    this.initForm();
  }

  public handleSubmitForm(): void {
    if (this.formGroup.valid) {
      this.submitForm$.emit({
        ...this.formGroup.getRawValue(),
        userType: this.userType
      });
    }
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group<SignUpForm>({
      password: new FormControl('', {
        validators: [
          Validators.required,
          Validators.maxLength(127),
          validatePassword
        ],
        nonNullable: true
      }),
      email: new FormControl('', {
        validators: [Validators.required, Validators.email],
        nonNullable: true
      }),
      ...([UserType.Individual, UserType.DemoPlayer].includes(this.userType)
        ? {}
        : {
            firstName: new FormControl('', {
              validators: [Validators.required, Validators.maxLength(35)],
              nonNullable: true
            }),
            lastName: new FormControl('', {
              validators: [Validators.required, Validators.maxLength(35)],
              nonNullable: true
            })
          }),
      ...(this.userType === UserType.Teacher
        ? {
            organization: new FormControl('', {
              validators: [Validators.required, Validators.maxLength(35)],
              nonNullable: true
            })
          }
        : {}),
      ...(this.userType === UserType.Student
        ? {
            classCode: new FormControl('', {
              validators: [Validators.required, Validators.maxLength(35)],
              nonNullable: true
            })
          }
        : {})
    });
  }
}
