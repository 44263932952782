export const formErrorMessages: { [key: string]: string | string[] } = {
  firstName: ['Provide a first name.', 'Invalid first name. Try again.'],
  lastName: ['Provide a last name', 'Invalid last name. Try again.'],
  email: ['Provide an email', 'Invalid email. Try again.'],
  password: ['Provide a password', 'Invalid password. Try again.'],
  confirmPassword: ['Provide a password', 'Invalid password. Try again.'],
  organization: [
    'Provide a school or organization name.',
    'Invalid school or organization name. Try again.'
  ]
};

export const passwordRules: string[] = [
  'One lowercase character',
  'One uppercase character',
  'One special character',
  'One number',
  'Six characters minimum'
];
