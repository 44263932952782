<div class="grid grid-cols-3 sign-up-wrapper h-full">
  <div class="w-full col-span-1">
    <div
      [ngClass]="{
        'bg-[url(assets/images/bg_sidebar_signup_individual.png)]': true
      }"
      class="bg-cover bg-no-repeat bg-center h-full relative"
    >
      <div
        class="flex justify-center items-center text-white absolute bottom-8 left-0 w-full"
      >
        <button
          class="flex justify-center items-center text-white"
          routerLink="/sign-in"
        >
          < Back
        </button>
      </div>
    </div>
  </div>
  <div class="col-span-2 flex p-10 items-center">
    <div>
      <div class="text-emerald-500 text-4xl mb-2">Verify it's you</div>
      <div class="text-gray-500">
        Click the link in the email we just sent you to play the Demo!
      </div>
    </div>
  </div>
</div>
