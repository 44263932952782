<div
  class="rounded-xl bg-white pt-6 pb-6 pl-14 pr-14 m-6 h-auto flex flex-col"
  *ngIf="plan"
>
  <p class="text-2xl text-sky-600 font-bold text-center mb-1 capitalize">
    {{ plan.name }}
  </p>

  <div class="text-2xl text-sky-400 font-bold text-center mb-6">
    ${{ plan.price | number }}
    <span *ngIf="plan.name === UserPlan.Academic">*</span>
  </div>

  <ul style="list-style-type: circle" class="mb-10">
    <div
      *ngIf="plan.name === UserPlan.Academic"
      class="text-gray-400 leading-7"
    >
      All the benefits of the Individual plan, plus...
    </div>
    <li class="text-gray-400 leading-7" *ngFor="let benefit of plan.benefits">
      {{ benefit }}
    </li>
  </ul>

  <ng-content></ng-content>
</div>
