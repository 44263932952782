import { Injectable } from '@angular/core';
import {
  concatMap,
  delay,
  filter,
  forkJoin,
  from,
  last,
  map,
  Observable,
  Observer,
  of,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { Request } from '../common/interfaces/request';
import { environment } from '../../environments/environment';
import {
  ADMIN,
  CHANGE_MEMBER_ROLE,
  CHANGE_PLAYER_ROLE,
  CLIENT,
  DELETE_GROUP,
  DELETE_MASTER_PLAYER_ACCOUNT,
  DELETE_PLAYER,
  EXECUTE_CLOUD_SCRIPT,
  GET_MEMBERS_WITH_ROLE_IDS,
  GET_PROFILE_INFO,
  GROUP,
  LIST_MEMBERSHIP,
  REMOVE_MEMBERS,
  SESSION_TICKET
} from '../common/data/consts';
import { HttpClient } from '@angular/common/http';
import { UserRole, UserStatus } from '../common/enums/user-role';
import {
  ListGroupMembers,
  Members,
  PlayerInfo,
  UserData
} from '../common/interfaces/user';
import { Entity, GroupMember } from '../common/interfaces/class-group';
import { ToastrService } from 'ngx-toastr';
import { responseHandler } from '../common/utils';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public progressPercentage: number = 0;
  private CSVDownloadSubjects: Subject<UserRole> = new Subject<UserRole>();
  private locationCSVDownloadSubjects: Subject<UserRole> =
    new Subject<UserRole>();
  private removePlayerSubjects: Subject<UserRole> = new Subject<UserRole>();

  public students?: UserData[];
  public teachers?: UserData[];
  public locationCSVDownload$: Observable<UserRole> =
    this.locationCSVDownloadSubjects.asObservable();
  public CSVDownload$: Observable<UserRole> =
    this.CSVDownloadSubjects.asObservable();
  public removePlayer$: Observable<UserRole> =
    this.removePlayerSubjects.asObservable();

  constructor(private httpClient: HttpClient, private toast: ToastrService) {}

  public nextDownloadCSV(role: UserRole): void {
    this.CSVDownloadSubjects.next(role);
  }

  public nextDownloadLocationCSV(role: UserRole): void {
    this.locationCSVDownloadSubjects.next(role);
  }

  public nextRemovePlayer(role: UserRole): void {
    this.removePlayerSubjects.next(role);
  }

  public getGroupWithRoleIdsList(
    RoleIds: UserRole[],
    Group: Entity
  ): Observable<ListGroupMembers[]> {
    return this.httpClient
      .post<Request<{ FunctionResult: ListGroupMembers[] }>>(
        `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
        {
          FunctionName: GET_MEMBERS_WITH_ROLE_IDS,
          FunctionParameter: { RoleIds, Group }
        }
      )
      .pipe(map(({ data }) => data.FunctionResult));
  }

  public getPlayerProfile(member: Members): Observable<UserData> {
    return this.httpClient
      .post<
        Request<{
          FunctionResult: { Data: UserData; Created: { Value: string } };
        }>
      >(`${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`, {
        FunctionName: GET_PROFILE_INFO,
        FunctionParameter: {
          PlayFabId: member.Lineage.master_player_account.Id
        }
      })
      .pipe(
        map(({ data }) => ({
          ...data.FunctionResult.Data,
          Created: data.FunctionResult.Created,
          roleId: { Value: member.RoleId as UserRole },
          key: member.Key
        }))
      );
  }

  public getPlayersProfileInfo(members: string[]): Observable<any> {
    const authorization = localStorage.getItem(SESSION_TICKET);
    return new Observable((observer: Observer<any>) => {
      fetch(
        'https://getuseraccountinfo.azurewebsites.net/api/getuseraccountinfo?',
        {
          method: 'POST',
          body: JSON.stringify({ members, authorization })
        }
      )
        .then((response) => response.json())
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((err) => observer.error(err));
    });
  }

  public getPlayersProfile(members: string[]): Observable<any> {
    const authorization = localStorage.getItem(SESSION_TICKET);
    return new Observable((observer: Observer<any>) => {
      fetch('https://usersdata.azurewebsites.net/api/getUserData?', {
        method: 'POST',
        body: JSON.stringify({ members, authorization })
      })
        .then((response) => response.json())
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((err) => observer.error(err));
    });
  }

  public updatePlayerRole(
    Group: Entity,
    Members: Entity[],
    OriginRoleId: UserRole,
    DestinationRoleId: UserStatus
  ): Observable<Request<Object>> {
    return this.httpClient
      .post<Request<Object>>(
        `${environment.baseApi}/${GROUP}/${CHANGE_MEMBER_ROLE}`,
        { Group, Members, OriginRoleId, DestinationRoleId }
      )
      .pipe(responseHandler(this.toast));
  }

  public changePlayerRole(
    Group: Entity,
    Members: Entity[],
    OriginRoleId: UserRole,
    DestinationRoleId: UserStatus
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<{ FunctionResult: GroupMember[] }>>(
      `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
      {
        FunctionName: CHANGE_PLAYER_ROLE,
        FunctionParameter: { Group, Members, OriginRoleId, DestinationRoleId }
      }
    );
  }

  public deletePlayerAccount(
    masterPlayerAccountId: string
  ): Observable<Request<Object>> {
    return this.deleteTitlePlayerAccount(masterPlayerAccountId).pipe(
      switchMap(() => this.deleteMasterPlayerAccount(masterPlayerAccountId))
    );
  }

  public deleteMasterPlayerAccount(
    playFabId: string
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${ADMIN}/${DELETE_MASTER_PLAYER_ACCOUNT}`,
      { PlayFabId: playFabId }
    );
  }

  public removeMasterPlayerAccount(role: UserRole): Observable<any> {
    return this.httpClient
      .post<Request<Object>>(
        `${environment.baseApi}/${GROUP}/${LIST_MEMBERSHIP}`,
        {
          Group: {
            Id: 'E6D1B590B3A9A6C0',
            Type: 'group',
            TypeString: 'group'
          }
        }
      )
      .pipe(
        switchMap((res) => {
          const members: ListGroupMembers[] = (res?.data as any)?.Members;
          if (!members) return of([]);
          const data: ListGroupMembers | undefined = members.find(
            (member) => member.RoleId === role
          );
          if (!data) return of([]);
          const membersIds = data!.Members.map(
            (m: Members) => m.Lineage.master_player_account.Id
          );
          return this.removePlayersProfilesInBatches(membersIds);
        })
      );
  }

  public listMembershipInfo(role: UserRole): Observable<any> {
    return this.httpClient
      .post<Request<Object>>(
        `${environment.baseApi}/${GROUP}/${LIST_MEMBERSHIP}`,
        {
          Group: {
            Id: 'E6D1B590B3A9A6C0',
            Type: 'group',
            TypeString: 'group'
          }
        }
      )
      .pipe(
        switchMap((res) => {
          const members: ListGroupMembers[] = (res?.data as any)?.Members;
          if (!members) return of([]);
          const data: ListGroupMembers | undefined = members.find(
            (member) => member.RoleId === role
          );
          if (!data) return of([]);
          const membersIds = data!.Members.map(
            (m: Members) => m.Lineage.master_player_account.Id
          );
          return this.getPlayersProfilesInfoInBatches(membersIds);
        })
      );
  }

  public listMembership(role: UserRole): Observable<UserData[]> {
    if (role === UserRole.STUDENT && this.students?.length) {
      return of(this.students);
    }
    if (role === UserRole.INSTRUCTOR && this.teachers?.length) {
      return of(this.teachers);
    }
    return this.httpClient
      .post<Request<Object>>(
        `${environment.baseApi}/${GROUP}/${LIST_MEMBERSHIP}`,
        {
          Group: {
            Id: 'E6D1B590B3A9A6C0',
            Type: 'group',
            TypeString: 'group'
          }
        }
      )
      .pipe(
        switchMap((res) => {
          const members: ListGroupMembers[] = (res?.data as any)?.Members;
          if (!members) return of([]);
          const data: ListGroupMembers | undefined = members.find(
            (member) => member.RoleId === role
          );
          if (!data) return of([]);
          const membersIds = data!.Members.map(
            (m: Members) => m.Lineage.master_player_account.Id
          );
          return this.getPlayersProfilesInBatches(membersIds);
        }),
        map((res) =>
          res.map((userData: UserData) => ({
            firstName: userData.firstName?.Value,
            lastName: userData.lastName?.Value,
            email: userData.email?.Value,
            organization:
              role === UserRole.STUDENT
                ? userData.className?.Value
                : userData.organization?.Value,
            Created: userData.email?.LastUpdated
              ? new Date(userData.email!.LastUpdated).toDateString()
              : '',
            username: userData.username?.Value,
            ...(role === UserRole.STUDENT
              ? {
                  episode_score: userData.episode_score?.Value,
                  vip_cash: userData.vip_cash?.Value,
                  episodes_cash: userData.episodes_cash?.Value,
                  episodes_history_key: userData.episodes_history_key?.Value
                }
              : {})
          }))
        ),
        tap((res) => this.setUserData(role, res))
      );
  }

  private removePlayersProfilesInBatches(
    membersIds: string[]
  ): Observable<string[]> {
    const batchSize: number = 1;
    const delayBetweenBatches: number = 1100;
    const playerProfiles: string[] = [];

    const chunkArray = (array: string[], size: number): string[][] => {
      const result: string[][] = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };

    const batches: string[][] = chunkArray(membersIds, batchSize);

    return new Observable((observer: Observer<string[]>) => {
      batches
        .reduce<Observable<unknown>>(
          (acc: Observable<unknown>, batch: string[], index: number) => {
            return acc.pipe(
              concatMap(() =>
                from(batch).pipe(
                  concatMap((id: string) =>
                    this.deletePlayerAccount(id).pipe(
                      tap(() => {
                        playerProfiles.push(id);
                        this.progressPercentage =
                          (playerProfiles.length * 100) / membersIds.length;
                      })
                    )
                  ),
                  last(),
                  delay(index < batches.length - 1 ? delayBetweenBatches : 0)
                )
              )
            );
          },
          of(null)
        )
        .subscribe({
          next: () => {},
          complete: () => {
            observer.next(playerProfiles);
            observer.complete();
          },
          error: (err) => observer.error(err)
        });
    });
  }

  private getPlayersProfilesInBatches(membersIds: string[]): Observable<any> {
    const batchSize = 27;
    const delayBetweenBatches = 1100;
    const playerProfiles: UserData[] = [];

    const chunkArray = (array: string[], size: number): string[][] => {
      const result: string[][] = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };

    const batches = chunkArray(membersIds, batchSize);

    return new Observable((observer: Observer<UserData[]>) => {
      batches
        .reduce((acc, batch, index) => {
          return acc.pipe(
            concatMap(() => {
              this.progressPercentage =
                (playerProfiles.length * 100) / membersIds.length;
              return this.getPlayersProfile(batch).pipe(
                tap((profiles) => playerProfiles.push(...profiles)),
                delay(index < batches.length - 1 ? delayBetweenBatches : 0)
              );
            })
          );
        }, of(null))
        .subscribe({
          next: () => {},
          complete: () => {
            observer.next(playerProfiles);
            observer.complete();
          },
          error: (err) => observer.error(err)
        });
    });
  }

  public deleteTitlePlayerAccount(
    playFabId: string
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${ADMIN}/${DELETE_PLAYER}`,
      { PlayFabId: playFabId }
    );
  }

  private getPlayersProfilesInfoInBatches(
    membersIds: string[]
  ): Observable<any> {
    const batchSize = 27;
    const delayBetweenBatches = 1100;
    const playerProfiles: PlayerInfo[][] = [];

    const chunkArray = (array: string[], size: number): string[][] => {
      const result: string[][] = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    };

    const batches = chunkArray(membersIds, batchSize);

    return new Observable((observer: Observer<PlayerInfo[][]>) => {
      batches
        .reduce((acc, batch, index) => {
          return acc.pipe(
            concatMap(() => {
              this.progressPercentage =
                (playerProfiles.length * 100) / membersIds.length;
              return this.getPlayersProfileInfo(batch).pipe(
                tap((profiles) => playerProfiles.push(...profiles)),
                delay(index < batches.length - 1 ? delayBetweenBatches : 0)
              );
            })
          );
        }, of(null))
        .subscribe({
          next: () => {},
          complete: () => {
            observer.next(playerProfiles);
            observer.complete();
          },
          error: (err) => observer.error(err)
        });
    });
  }

  public deleteGroup(group: Entity): Observable<Request<Object>> {
    return this.httpClient.post<Request<{ FunctionResult: GroupMember[] }>>(
      `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
      {
        FunctionName: DELETE_GROUP,
        FunctionParameter: { Group: group }
      }
    );
  }

  public removeMembers(
    Group: Entity,
    Members: Entity[]
  ): Observable<{ teacher: Members; group: Entity }> {
    const batchSize = 5;

    const removeMembersRequest$ = this.httpClient.post<
      Request<{
        FunctionResult: {
          students: Members[];
          teachers: Members[];
          group: Entity;
        };
      }>
    >(`${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`, {
      FunctionName: REMOVE_MEMBERS,
      FunctionParameter: { Group, Members }
    });

    return removeMembersRequest$.pipe(
      filter(({ data }) => !!data?.FunctionResult),
      switchMap(({ data }) => {
        const { students, teachers, group } = data.FunctionResult;
        const deleteBatch = (
          batch: Members[],
          index: number
        ): Observable<{ teacher: Members; group: Entity }> => {
          const batchIds = batch.slice(index, index + batchSize);
          if (batchIds.length === 0) {
            return of({ teacher: teachers[0], group });
          }
          return forkJoin(
            batchIds.map((member: Members) =>
              this.deletePlayerAccount(member.Lineage.master_player_account.Id)
            )
          ).pipe(switchMap(() => deleteBatch(batch, index + batchSize)));
        };

        return deleteBatch(students, 0);
      })
    );
  }

  private setUserData(role: UserRole, data: UserData[]) {
    switch (role) {
      case UserRole.INSTRUCTOR:
        this.teachers = data;
        break;
      case UserRole.STUDENT:
        this.students = data;
        break;
    }
  }
}
