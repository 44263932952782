import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const passwordValidationRegExp = new RegExp(
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$'
);

export const validatePassword: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null =>
  passwordValidationRegExp?.test(control?.value || '')
    ? null
    : { password: true };

export const comparePasswords: ValidatorFn = (
  group: AbstractControl
): ValidationErrors | null => {
  const { password, confirmPassword } = group.getRawValue();
  if (password && confirmPassword && password !== confirmPassword) {
    return { notSame: true };
  }
  return null;
};
