<app-header></app-header>
<div class="container mx-auto my-8 p-8 bg-white rounded shadow-md">
  <h1 class="text-3xl font-bold mb-6">TideMill Group Game Privacy Policy</h1>

  <p class="mb-4"><strong>Last Updated:</strong> 2/10/2025</p>

  <p class="mb-4">
    This Privacy Policy applies to the “Beat the Fed” game, owned and operated by TideMill
    Group LLC (“TideMill Group,” “we,” “us”), and available at
    <a href="https://beatthefed.com/" class="text-blue-500">https://beatthefed.com/</a>
    (the “Game”). This Privacy Policy is intended to inform our education Institutions and
    individuals, such as teachers and students (collectively, “Users,” “You,” “Your”) who
    access or play the Game about how we may collect, use, and disclose your personal
    information. It also describes your rights regarding your personal information.
    By accessing or using any TideMill Group Game, you acknowledge that you have read
    and understood this Privacy Policy.
  </p>

  <h2 class="text-2xl font-bold mt-6 mb-4">Contents</h2>

  <ul class="list-disc ml-6">
    <li>Personal Information We Collect</li>
    <div class="pl-4">
      <li>Students Under Age 13</li>
      <li>Family Educational Rights and Privacy Act (FERPA)</li>
      <li>Usage Information and Cookies</li>
    </div>
    <li>How We Use and Disclose Personal Information</li>
    <li>How You May Modify Your Personal Information</li>
    <li>Third Parties</li>
    <li>Security</li>
    <li>Data Retention</li>
    <li>Opt-Out Policy</li>
    <li>Updates to This Policy</li>
    <li>Contact</li>
  </ul>

  <h2 id="personal-information" class="text-2xl font-bold mt-6 mb-4">
    Personal Information We Collect
  </h2>

  <p>
    When we engage with an Institution or when you access or use our Game, we may
    collect certain personal information about you which identifies, describes, or reasonably
    relates to an identifiable person or household, or that is otherwise defined as personal,
    information or protected data under applicable laws (“Personal Information”).
  </p>

  <p class="mt-4">
    <strong>In order to provide access to the Game, we require the following Personal Information:</strong>
  </p>

  <div class="flex ml-6 border-[#000] border-[1px]">
    <span class="border-[#000] border-r-[1px] p-2">From Teachers</span>
    <div class="flex flex-col ml-6">
      <span>First and Last Name</span>
      <span>Email Address</span>
      <span>User Name</span>
      <span>Password</span>
      <span>School Name or Organization Name</span>
      <span>Optionally, Teachers may provide their grade level</span>
    </div>
  </div>

  <p class="mt-4 mb-4">
    Teachers are provided with a code which they provide to their Students to invite them
    into the Game.
  </p>

  <div class="flex ml-6 border-[#000] border-[1px]">
    <span class="border-[#000] border-r-[1px] p-2">From Students</span>
    <div class="flex flex-col ml-6">
      <span>First and Last Name</span>
      <span>Email Address</span>
      <span>User Name</span>
      <span>Password</span>
      <span>Avatar</span>
    </div>
  </div>

  <p class="mt-4">
    Student User Names, avatar, and game score are displayed on a Game-wide
    leaderboard for access by all Users.
  </p>

  <h3 id="children-under-age-13" class="text-lg font-bold mt-4 mb-2 underline">
    Students Under Age 13
  </h3>

  <p>
    The Game is not intended for use by children under age 13, and we do not knowingly
    collect Personal Information from children. Should we learn that we have inadvertently
    collected Personal Information from a child, we will take steps to promptly delete it.
  </p>

  <h3 id="ferpa" class="text-lg font-bold mt-4 mb-2 underline">
    Family Educational Rights and Privacy Act (FERPA)
  </h3>

  <p>
    When the Game is used within an education institution that is subject to the Family
    Educational Rights and Privacy Act (“FERPA”), we ask the authorized Institution
    representative to agree that TideMill Group has a “legitimate educational interest” in
    providing the Game, and to designate TideMill Group as a “school official” as the terms
    are used in FERPA §§ 99.31(a)(1). TideMill Group shall remain under the direct control
    of the Institution with respect to the use and maintenance of FERPA-protected records
    and shall use Student Personal Information only to provide the Game as described in
    our Terms of Use and any contract we may have with the Institution.
  </p>

  <h2 id="usage-information" class="text-2xl font-bold mt-6 mb-4 underline">
    Usage Information and Cookies
  </h2>

  <p>
    When you access our Game, our servers automatically collect the Internet Protocol
    (“IP”) address associated with the browser and the device ID. We may also collect
    additional information such as login timestamp, browser type and version, the operating
    system of the computer or device and language, geolocation, as well as areas in the
    Game that Users visit, time spent in those areas of the Game, and features accessed
    (“Usage Information”). We collect this information to help us to deliver and secure the
    Game, and to diagnose technical problems. We also aggregate and analyze Usage
    Information to help us understand how our Game is used and to help us improve our
    Game. When Usage Information is in a format that identifies an individual, we treat it as
    Personal Information in accordance with this Privacy Policy.
  </p>

  <p class="mt-4">
    To collect Usage Information, we use cookies and similar technologies. Cookies are
    small data files sent by the Game and stored on the computer or device. Cookies store
    information related to your browser to enable us to recognize the browser on return
    visits to the Game, to remember your preferences, to understand how our Users interact
    with the Game, and to help improve the Game.
  </p>

  <p class="mt-4">
    <strong>By using the Game, you agree to the use of these technologies.</strong>
  </p>

  <p class="mt-4 underline"><strong>Third Party Cookies</strong></p>

  <p>
    We use Google Analytics to assist us in collecting and understanding Usage
    Information. To learn more about Google Analytics, visit their
    <a
      href="https://policies.google.com/privacy"
      class="text-blue-500"
      target="_blank"
    >privacy policy</a>. You may
    opt out of Google Analytics by downloading their browser add-on, available at
    <a
      href="https://tools.google.com/dlpage/gaoptout"
      class="text-blue-500"
      target="_blank"
    >https://tools.google.com/dlpage/gaoptout</a>.
    Note that we are not responsible for their
    opt-out tool.
  </p>

  <p class="mt-4">
    In addition, most browsers can be set to detect browser cookies and to let a user reject
    them, but refusing cookies may impact your ability to use the Game. To learn more
    about browser cookies, including how to manage or delete them, refer to the Tools, Help
    or similar section of your web browser.
  </p>

  <h2 id="use-disclosure" class="text-2xl font-bold mt-6 mb-4">
    How We Use and Disclose Personal information
  </h2>

  <p>
    We do not sell, rent, or make other commercial use of Personal Information we receive
    via the Game. We use Personal Information as follows:
  </p>

  <ul class="list-disc ml-6 mt-4">
    <li>
      To provide the Game, including to create and manage log-in credentials, which
      are used to create User accounts, authenticate Users, and maintain and
      administer the Game;
    </li>
    <li>
      To post and maintain a Game-wide leaderboard which discloses the User Name,
      avatar, and game score from all Users to all other Game Users;
    </li>
    <li>
      To share with third-party service providers who provide certain features, such as
      server maintenance, security, analytics, and cloud storage on our behalf;
    </li>
    <li>
      To provide Institution support, including to resolve a problem or support issue,
      notify Institutions about changes to the Game, and to otherwise communicate
      and solicit feedback on Institution experiences with the Game.
    </li>
    <li>
      In connection with our legal or regulatory obligations, to protect our rights or the
      rights and safety of our Institutions and Users, or as necessary in the event of a
      court order, regulatory inquiry, or other lawful request. Provided, however, that
      unless legally prohibited, we will direct any such request to the Institution or notify
      the Institution before responding.
    </li>
    <li>
      In relation to a known or suspected violation of our terms of use, fraud
      prevention, or other unlawful use, including, as necessary for these purposes,
      sharing Personal Information with entities assisting us in an investigation and as
      may be required by applicable law.
    </li>
    <li>
      In the event of a reorganization, merger, sale, assignment, bankruptcy, or similar
      business change, we may need to transfer your Personal Information to that
      re-organized entity or new owner after the sale or reorganization for them to use
      in accordance with this Privacy Policy.
    </li>
    <li>
      To provide Institutions and Teachers with updates and offers about our Game. At
      any time, you may unsubscribe or opt-out of further communication on any
      electronic marketing communication by using the link labeled “unsubscribe”
      available in each email communication or by contacting us at
      <a href="mailto:info@tidemillgroup.com" class="text-blue-500">info&#64;tidemillgroup.com</a>.
    </li>
  </ul>

  <h2 id="de-identified-information" class="font-bold mt-6 mb-4">
    We may also create and retain aggregated, de-identified information for lawfully
    permissible purposes.
  </h2>

  <h2 id="modify-personal-information" class="text-2xl font-bold mt-6 mb-4">
    How You May Modify Your Personal Information
  </h2>

  <p>
    Teachers may correct or change the Personal Information collected during registration
    by emailing us at
    <a href="mailto:info@tidemillgroup.com" class="text-blue-500">info&#64;tidemillgroup.com</a>.
    Students may request to access, amend,
    correct, or delete their Personal Information by contacting the Institution or their
    Teacher. TideMill Group will work with Teachers and Students to facilitate these
    requests.
  </p>

  <p class="mt-4">
    Institutions may also directly request deletion of their students’ Personal Information at
    any time by contacting TideMill Group at
    <a href="mailto:info@tidemillgroup.com" class="text-blue-500">info&#64;tidemillgroup.com</a>.
  </p>

  <h2 id="third-parties" class="text-2xl font-bold mt-6 mb-4">Third Parties</h2>

  <p>
    We may engage with third-party partners to facilitate our delivery of the Game and to
    provide certain features on our behalf such as data hosting, analytics, content delivery,
    maintenance, security, and similar functions. These third parties may require a limited
    amount of information, including Personal Information, in order to deliver their Game on
    our behalf.
  </p>

  <p class="mt-4">
    We do not disclose more Personal Information than is necessary for a third party to
    facilitate our delivery of the Game or to provide certain features on our behalf. These
    third-party service providers are contractually bound to use Personal Information for the
    sole purpose of providing their Game to us in support of our delivery of the Game we
    provide.
  </p>

  <h2 id="security" class="text-2xl font-bold mt-6 mb-4">Security</h2>

  <p>
    We implement physical, administrative, and technical safeguards to help protect the
    confidentiality, integrity and availability of your Personal Information. However, the
    Internet is not perfectly secure, and we are not responsible for security breaches not
    reasonably within our control.
  </p>

  <p class="mt-4">
    You are responsible for maintaining the confidentiality of your account credentials. If you
    know or suspect that there has been any unauthorized use of an account, loss of
    account credentials or if you suspect a security breach, notify us at
    <a href="mailto:info@tidemillgroup.com" class="text-blue-500">info&#64;tidemillgroup.com</a>.
  </p>

  <h2 id="data-retention-2" class="text-2xl font-bold mt-6 mb-4">
    Data Retention
  </h2>

  <p>
    At any time upon termination of an agreement or otherwise at their discretion,
    Institutions may submit a written request to have their Users’ Personal information
    securely destroyed. We will comply with such written requests within 30 days.
  </p>

  <p class="mt-4">
    We delete all Student Personal Information once per year on or about July 1st.
    We may retain certain Institution information for lawfully required business
    record-keeping purposes, however, this would not include Student Personal Information.
  </p>

  <p class="mt-4">
    We also retain non-Personal Information, including aggregated, de-identified data for
    lawfully permissible purposes.
  </p>

  <h2 id="opt-out-policy" class="text-2xl font-bold mt-6 mb-4">
    Opt-Out Policy
  </h2>

  <p>
    We may send marketing emails to Institutions and Teachers with information about our
    Game that we believe may be of interest. Institutions may opt-out of receiving email
    messages from TideMill Group by contacting us at
    <a href="mailto:info@tidemillgroup.com" class="text-blue-500">info&#64;tidemillgroup.com</a>
    or by clicking
    on the “unsubscribe” link found at the bottom of every email that we send. We do not
    send these communications to Students.
  </p>

  <p class="mt-4">
    If you have opted out of receiving marketing communications from us, we may still send
    service-related communications regarding the Game.
    We do not send email messages on behalf of third parties.
  </p>

  <p class="mt-4">We do not send email messages on behalf of third parties.</p>

  <h2 id="updates-to-policy" class="text-2xl font-bold mt-6 mb-4">
    Updates to This Policy:
  </h2>

  <p>
    As our Game evolves, we may make changes to this Privacy Policy. We will alert you to
    changes by providing notice within the Game or otherwise as required by law, and we
    will adjust the “Last Updated” date at the beginning of this Privacy Policy.
  </p>

  <h2 id="contact" class="text-2xl font-bold mt-6 mb-4">Contact:</h2>

  <p class="mt-8 text-sm">
    If you have any questions about this Privacy Policy, please contact us at: TideMill
    Group, 120 East End Avenue, New York, NY, 10028 or i
    <a href="mailto:info@tidemillgroup.com" class="text-blue-500">info&#64;tidemillgroup.com</a>.
  </p>
</div>
