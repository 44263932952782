<app-header></app-header>

<app-loader *ngIf="isLoading; else contentRef"></app-loader>

<ng-template #contentRef>
  <div class="flex flex-col justify-start items-stretch h-full p-4">
    <div class="container">
      <div
        *ngIf="!errorMessage; else errorRef"
        class="row justify-content-center"
      >
        <div class="col-md-5">
          <div class="message-box _success bg-[white]">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            <h2>Your payment was successful</h2>
            <p>Thank you for your payment.</p>
            <a class="text-sky-400 underline ml-2" routerLink="/">Home</a>
          </div>
        </div>
      </div>

      <hr />

      <ng-template #errorRef>
        <div class="row justify-content-center">
          <div class="col-md-5">
            <div class="message-box _success _failed bg-[white]">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
              <h2>Your payment failed</h2>
              <p class="mb-2">{{ errorMessage }}</p>
              <a
                class="text-sky-400 underline ml-2"
                routerLink="/dashboard/checkout"
                >Try again later.</a
              >
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
