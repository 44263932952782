import { Component, OnInit } from '@angular/core';
import { catchError, EMPTY, finalize, Observable, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SignUpFormData } from '../../common/interfaces/user';
import { UserType } from 'src/app/common/enums/user-type';
import { filterRequestCode } from '../../common/utils';

@UntilDestroy()
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public requestErrorMessage?: string;
  public userType!: UserType;
  public UserType = UserType;
  public loading: boolean = false;
  public isStockTrak: boolean = false;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((params: Params) => this.fetchUserType(params));
  }

  ngOnInit(): void {
    this.isStockTrak =
      this.activatedRoute.snapshot.queryParams['userType'] === 'StockTrak';
  }

  public signUp(userFormData: SignUpFormData): void {
    this.loading = true;
    this.authService
      .signUp(userFormData, this.isStockTrak)
      .pipe(
        catchError((e) => {
          const error = e?.error;
          const errorMessage = error?.errorMessage ?? e?.message;
          return this.handleErrorResponse(errorMessage || e?.errorMessage);
        }),
        filterRequestCode,
        switchMap((res) => {
          const resData: any = res?.data;
          const error = resData?.FunctionResult?.Error || resData?.Error;
          if (error) {
            return this.handleErrorResponse(error.Message);
          } else {
            return this.fetchSignUpResponse(userFormData);
          }
        }),
        finalize(() => (this.loading = false)),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public fetchSignUpResponse(
    userFormData: SignUpFormData
  ): Promise<any> | Observable<any> {
    switch (this.userType) {
      case UserType.Student:
      case UserType.Individual:
      case UserType.Teacher:
        this.loading = true;
        return this.authService.signIn(userFormData).pipe(
          catchError((e) => {
            this.loading = false;
            const error = e?.error;
            const errorMessage = error?.errorMessage ?? e?.message;
            return this.handleErrorResponse(errorMessage || e?.errorMessage);
          })
        );
      case UserType.DemoPlayer:
        return this.router.navigateByUrl('/verification');
      default:
        return this.router.navigateByUrl('/');
    }
  }

  public handleChangeUserType(userType: string): void {
    this.router.navigate(['/sign-up'], {
      queryParams: {
        userType: userType
      }
    });
  }

  private handleErrorResponse(errorMessage: string) {
    this.requestErrorMessage = errorMessage ?? 'Something went wrong';
    this.loading = false;
    return EMPTY;
  }

  private fetchUserType(res: Params): void {
    const type = res['userType'];
    if (type === 'StockTrak') {
      this.userType = UserType.Teacher;
      return;
    }
    switch (type) {
      case UserType.Student:
      case UserType.Individual:
      case UserType.Teacher:
        this.userType = type;
        break;
      default:
        this.router.navigateByUrl('/');
        break;
    }
  }
}
