import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { catchError, EMPTY } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterRequestCode } from '../../common/utils';

@UntilDestroy()
@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent {
  public resetClicked = false;
  public loading = false;
  public confirmEmailFormControl: FormControl<string> = new FormControl<string>(
    '',
    { validators: [Validators.required, Validators.email], nonNullable: true }
  );

  constructor(private authService: AuthService) {}

  public handleReset() {
    this.loading = true;
    this.authService
      .sendRecoveryEmail(this.confirmEmailFormControl.value)
      .pipe(
        catchError(() => {
          this.loading = false;
          return EMPTY;
        }),
        filterRequestCode,
        untilDestroyed(this)
      )
      .subscribe(() => (this.resetClicked = true));
  }
}
