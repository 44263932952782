import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { UserRole } from '../common/enums/user-role';
import { UserType } from '../common/enums/user-type';
import { environment } from '../../environments/environment';
import {
  AccountInfo,
  GroupRole,
  ResetUserFormData,
  SignInFormData,
  SignUpFormData
} from '../common/interfaces/user';
import {
  ADD_USERNAME_PASSWORD,
  ADMIN,
  CLIENT,
  ENTITY_ID,
  ENTITY_TOKEN,
  ENTITY_TYPE,
  EXECUTE_CLOUD_SCRIPT,
  GET_USER_ACCOUNT_INFO,
  GRAND_ITEMS_TO_USER,
  LOGGED_IN,
  LOGIN_WITH_EMAIL,
  LOGIN_WITH_SERVER_CUSTOM_ID,
  NOT_APPROVED_USER_ERROR,
  RECOVERY_EMAIL,
  REGISTER_USER,
  RESET_PASSWORD,
  ROLE,
  SERVER,
  SESSION_TICKET,
  SIGN_UP_STUDENT,
  USER_EXIST_ERROR
} from '../common/data/consts';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { isCorrectRole, utils } from '../common/utils';
import { Request } from '../common/interfaces/request';
import { Auth } from '../common/interfaces/auth';
import { CustomTags } from '../common/interfaces/custom-tags';
import { Router } from '@angular/router';
import { ClassesService } from './classes.service';
import { stocktrakSeatId } from '../common/data/choose-plans';
import { Entity } from '../common/interfaces/class-group';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private userService: UserService,
    private classesService: ClassesService,
    private localStorage: LocalStorageService
  ) {}

  public isAuthenticated(): boolean {
    return !!this.localStorage.getItem(ENTITY_TOKEN);
  }

  public signUp(
    signUpFormData: SignUpFormData,
    strockTrak?: boolean
  ): Observable<Request<Object>> {
    const {
      password,
      email,
      firstName,
      userType,
      lastName,
      organization,
      classCode
    } = signUpFormData;
    const customTags = {
      email,
      firstName,
      userType,
      lastName,
      organization,
      classCode,
      ...(strockTrak && { strockTrak })
    };
    switch (userType) {
      case UserType.Student:
        return this.signUpForStudent(
          { ...customTags, role: UserRole.STUDENT },
          password
        );
      case UserType.Teacher:
        return this.signUpWithRole(
          { ...customTags, role: UserRole.INSTRUCTOR },
          password
        );
      case UserType.Individual:
        return this.signUpWithRole(
          { ...customTags, role: UserRole.INDIVIDUAL },
          password
        );
      case UserType.DemoPlayer:
        return this.signUpWithRole(
          { ...customTags, role: UserRole.DEMO_PLAYER },
          password
        );
    }
  }

  public signIn(
    signInFormData: SignInFormData
  ): Observable<Request<{ AccountInfo: AccountInfo }>> {
    const { email, password } = signInFormData;
    return this.httpClient
      .post<Request<Auth>>(
        `${environment.baseApi}/${CLIENT}/${LOGIN_WITH_EMAIL}`,
        {
          Email: email,
          Password: password,
          TitleId: environment.titleId
        }
      )
      .pipe(
        tap((res) => this.setTicketAndEntityData(res)),
        switchMap(({ data }) =>
          this.userService.getUserRoles(data.EntityToken.Entity)
        ),
        switchMap((groupRoles: GroupRole[]) => {
          const role = groupRoles[0]?.RoleId;
          return isCorrectRole(role)
            ? of(role)
            : throwError(() => NOT_APPROVED_USER_ERROR);
        }),
        catchError((e) => {
          this.localStorage.clearLocalStorage();
          return throwError(() => e);
        }),
        tap((role: UserRole) => this.localStorage.setItem(ROLE, role)),
        switchMap(() => this.userService.getUserData()),
        tap(({ data }) =>
          this.localStorage.setItem(
            LOGGED_IN,
            JSON.stringify(!!(data as any)?.Data?.logged_in?.Value)
          )
        ),
        switchMap(() => this.userService.getAccountInfo()),
        tap(() => {
          const role = localStorage.getItem(ROLE);
          const accountId = this.userService.getUser()?.accountId;
          const loggedIn = this.localStorage.getItem(LOGGED_IN);
          if (
            role === UserRole.INSTRUCTOR &&
            loggedIn &&
            !JSON.parse(loggedIn)
          ) {
            this.router.navigateByUrl(
              `/dashboard/${UserRole.INSTRUCTOR}/${accountId}/information`
            );
          } else {
            this.router.navigateByUrl(utils(role, accountId));
          }
        })
      );
  }

  public sendRecoveryEmail(email: string): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${ADMIN}/${RECOVERY_EMAIL}`,
      {
        Email: email,
        EmailTemplateId: environment.emailTemplateId
      }
    );
  }

  public resetPassword(
    resetUserFormData: ResetUserFormData
  ): Observable<Request<Object>> {
    const { token, password } = resetUserFormData;
    return this.httpClient
      .post<Request<Object>>(
        `${environment.baseApi}/${ADMIN}/${RESET_PASSWORD}`,
        {
          Token: token,
          Password: password
        }
      )
      .pipe(tap(this.localStorage.clearLocalStorage));
  }

  private setTicketAndEntityData({ data }: { data: Auth }): void {
    this.localStorage.setItem(ENTITY_TOKEN, data.EntityToken.EntityToken);
    this.localStorage.setItem(ENTITY_ID, data.EntityToken.Entity.Id);
    this.localStorage.setItem(ENTITY_TYPE, data.EntityToken.Entity.Type);
    this.localStorage.setItem(SESSION_TICKET, data.SessionTicket);
  }

  private signUpWithRole(
    customTags: CustomTags,
    password: string
  ): Observable<Request<Object>> {
    let entity: Entity;
    return this.httpClient
      .post<Request<Auth>>(
        `${environment.baseApi}/${CLIENT}/${REGISTER_USER}`,
        {
          TitleId: environment.titleId,
          Password: password,
          Email: customTags.email,
          CustomTags: customTags,
          RequireBothUsernameAndEmail: false
        }
      )
      .pipe(
        tap((res: Request<Auth>) => this.setTicketAndEntityData(res)),
        switchMap(({ data }) => {
          entity = data.EntityToken.Entity;
          if (customTags.role === UserRole.INSTRUCTOR) {
            return this.classesService.createClass(
              data.EntityToken.Entity.Id,
              data.EntityToken.Entity
            );
          }
          return of(null);
        }),
        switchMap(() => {
          if (
            customTags.role === UserRole.INSTRUCTOR &&
            customTags.strockTrak
          ) {
            return this.grandItemsToUser(entity);
          }
          return of(null);
        }),
        switchMap(() =>
          this.userService.addOrUpdateUserEmail(customTags.email)
        ),
        tap(() => this.localStorage.clearLocalStorage())
      );
  }

  public checkIsEmailExists(email: string): Observable<null | never> {
    return this.httpClient
      .post(
        `${environment.baseApi}/${ADMIN}/${GET_USER_ACCOUNT_INFO}`,
        {
          Email: email
        },
        {
          headers: {
            'X-SecretKey': environment.secretKey
          }
        }
      )
      .pipe(
        catchError(() => of(null)),
        switchMap((res) =>
          res ? throwError(() => USER_EXIST_ERROR) : of(null)
        )
      );
  }

  private loginWithServerCustomId(
    customTags: CustomTags
  ): Observable<Request<Auth>> {
    return this.httpClient.post<Request<Auth>>(
      `${environment.baseApi}/${SERVER}/${LOGIN_WITH_SERVER_CUSTOM_ID}`,
      {
        TitleId: environment.titleId,
        CreateAccount: true,
        ServerCustomId: customTags.email,
        CustomTags: {
          ...customTags,
          grade_level: 0,
          avatar_config: {},
          className: '',
          vip_cash: 0
        }
      }
    );
  }

  private signUpForStudent(
    customTags: CustomTags,
    password: string
  ): Observable<Request<Object>> {
    return this.checkIsEmailExists(customTags.email).pipe(
      switchMap(() => this.loginWithServerCustomId(customTags)),
      switchMap(({ data }) =>
        this.checkCodeAndRegisterStudent(data, password, customTags)
      ),
      tap(() => this.localStorage.clearLocalStorage())
    );
  }

  public signOut(): void {
    this.localStorage.clearLocalStorage();
    this.router.navigateByUrl('');
  }

  private addUsernamePassword(
    customTags: CustomTags,
    password: string,
    playFabId: string,
    token: string
  ): Observable<Request<Object>> {
    return this.httpClient
      .post<Request<{ Username: string }>>(
        `${environment.baseApi}/${CLIENT}/${ADD_USERNAME_PASSWORD}`,
        {
          Email: customTags.email,
          Password: password,
          Username: playFabId,
          CustomTags: customTags
        },
        {
          headers: {
            'X-Authorization': token
          }
        }
      )
      .pipe(catchError((error) => throwError(error)));
  }

  private checkCodeAndRegisterStudent(
    data: Auth,
    password: string,
    customTags: CustomTags
  ): Observable<Request<object>> {
    return this.httpClient
      .post<Request<Auth>>(
        `${environment.baseApi}/${SERVER}/${EXECUTE_CLOUD_SCRIPT}`,
        {
          FunctionName: SIGN_UP_STUDENT,
          FunctionParameter: {
            password,
            CustomTags: { ...customTags, LoginResult: data }
          },
          PlayFabId: data.PlayFabId
        },
        {
          headers: {
            'X-SecretKey': environment.secretKey
          }
        }
      )
      .pipe(
        switchMap((res) => {
          const resData: any = res?.data;
          const error = resData?.FunctionResult?.Error || resData?.Error;
          if (error) {
            return of(res);
          }
          return this.addUsernamePassword(
            customTags,
            password,
            data.PlayFabId,
            data.EntityToken.EntityToken
          );
        })
      );
  }

  private grandItemsToUser(entity: Entity): Observable<Object> {
    const items: string[] = new Array(250).fill(stocktrakSeatId);
    return this.httpClient.post(
      `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
      {
        FunctionName: GRAND_ITEMS_TO_USER,
        FunctionParameter: { entity, items }
      }
    );
  }
}
