<div
  class="flex w-full flex-col relative items-center h-fit"
  [class]="userType === UserType.Teacher ? 'bg-[#ffffde]' : 'bg-[#d9ffff]'"
>
  <div
    class="flex flex-col pt-8 w-full max-w-[600px] bg-center bg-no-repeat bg-cover h-[310px]"
    [class]="
      userType === UserType.Teacher
        ? 'bg-[url(assets/images/instructorPick.png)]'
        : 'bg-[url(assets/images/studentPick.png)]'
    "
  >
    <p
      [class]="
        userType === UserType.Teacher ? 'text-amber-600' : 'text-sky-600'
      "
      class="text-xl text-center"
    >
      {{ userType }}
    </p>
  </div>
  <button
    (click)="action$.emit(userType)"
    [class]="
      userType === UserType.Teacher
        ? 'from-[#f89e35] to-[#f79320]'
        : 'from-[#419ff8] to-[#3593f7]'
    "
    class="bg-gradient-to-t text-white w-full bottom-0 text-center text-xl px-2 py-5 capitalize"
  >
    {{ btnText }}
  </button>
</div>
