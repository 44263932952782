import {
  ComponentRef,
  Injectable,
  Type,
  ViewContainerRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { ConfirmButtons } from '../common/enums/popup-enums';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private componentRef!: ComponentRef<any>;
  private componentSubscriber!: Subject<string>;
  private entry?: ViewContainerRef;

  constructor() {}

  public setPopupEntry(entry: ViewContainerRef): void {
    this.entry = entry;
  }

  public openModal(data: any, component: Type<any>) {
    if (!this.entry) throw Error('Need entry for popup');
    this.entry.clear();
    this.componentRef = this.entry.createComponent(component);
    this.componentRef.instance.confirmEvent?.subscribe(() => this.confirm());
    this.componentRef.instance.data = data;
    this.componentRef.instance.closeEvent?.subscribe(() => this.closeModal());
    this.componentSubscriber = new Subject<string>();
    return this.componentSubscriber.asObservable();
  }

  public closeModal(): void {
    this.componentSubscriber.complete();
    this.componentRef.destroy();
  }

  public confirm(): void {
    this.componentSubscriber.next(ConfirmButtons.Confirm);
    this.closeModal();
  }
}
