import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PopupService } from './services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'samurai-admin';
  @ViewChild('popup', { read: ViewContainerRef, static: true })
  entry!: ViewContainerRef;

  constructor(private popupService: PopupService) {}

  ngOnInit(): void {
    this.popupService.setPopupEntry(this.entry);
  }
}
