<div
  class="fixed flex items-center justify-center z-[999] left-0 top-0 w-full h-full bg-[rgba(0,0,0,0.3)]"
>
  <div class="lds-roller" [class.full-screen]="fullScreen">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
