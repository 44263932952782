export const environment = {
  production: false,
  interface: 'beatthefed',
  gameUrl: 'https://prod-game.beatthefed.com',
  gameDemoUrl: 'https://prod-demo-game.beatthefed.com',
  titleId: 'EA097',
  emailTemplateId: '190F25B423204055',
  secretKey: 'F18E5O3BPUGOWSBB11MB9BXEPCINFSNZ3YAB5MEIMI8YQYCJDD',
  baseApi: `https://EA097.playfabapi.com`
};
