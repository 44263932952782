export const TITLE_PLAYER_ACCOUNT = 'title_player_account';
export const CHECKOUT_CONFIG = 'checkout_config';
export const LOGGED_IN = 'logged_in';
export const FIRST_LOGIN = 'firstLogin';

export const ROLE = 'Role';
export const USER = 'User';
export const ADMIN = 'Admin';
export const GROUP = 'Group';
export const CLIENT = 'Client';
export const SERVER = 'Server';

export const ENTITY_TOKEN = 'EntityToken';
export const ENTITY_ID = 'EntityId';
export const ENTITY_TYPE = 'EntityType';
export const PLAYFABID = 'PlayFabId';
export const SESSION_TICKET = 'SessionTicket';

export const RESET_PASSWORD = 'ResetPassword';
export const CHANGE_MEMBER_ROLE = 'ChangeMemberRole';
export const GET_GROUP = 'GetGroup';
export const DELETE_GROUP = 'deleteGroup';
export const CHANGE_PLAYER_ROLE = 'changePlayerRole';
export const EXECUTE_CLOUD_SCRIPT = 'ExecuteCloudScript';
export const DELETE_MASTER_PLAYER_ACCOUNT = 'DeleteMasterPlayerAccount';
export const LIST_MEMBERSHIP = 'ListGroupMembers';
export const REMOVE_MEMBERS = 'removeMembers';
export const LOGIN_WITH_EMAIL = 'LoginWithEmailAddress';
export const DELETE_PLAYER = 'DeletePlayer';
export const ACCOUNT_INFO = 'GetAccountInfo';
export const REGISTER_USER = 'RegisterPlayFabUser';
export const RECOVERY_EMAIL = 'SendAccountRecoveryEmail';
export const ADD_OR_UPDATE_EMAIL = 'AddOrUpdateContactEmail';
export const LOGIN_WITH_SERVER_CUSTOM_ID = 'LoginWithServerCustomId';
export const ADD_USERNAME_PASSWORD = 'AddUsernamePassword';

export const GET_MEMBERS_WITH_ROLE_IDS = 'getMembersWithRoleIds';
export const SIGN_UP_STUDENT = 'signUpStudent';
export const GET_PROFILE_INFO = 'getProfileInfo';
export const CREATE_GROUP = 'createGroup';
export const GRAND_ITEMS_TO_USER = 'grandItemsToUser';
export const UPDATE_GROUP = 'updateGroup';
export const GET_TITLE_MEMBERSHIP = 'getTitleMembership';
export const CREATE_STUDENT = 'createStudent';
export const CREATE_STUDENTS = 'createStudents';
export const GET_STUDENT_MASTER_PLAYER_ID = 'getStudentMasterPlayerId';
export const UPDATE_STUDENT = 'updateStudent';
export const GET_USER_ROLES = 'getUserRoles';
export const GET_USER_ACCOUNT_INFO = 'GetUserAccountInfo';
export const GET_USER_GROUP = 'getUserGroup';
export const GET_PLAYER_FILES = 'getPlayerFiles';
export const START_PURCHASE = 'StartPurchase';
export const PAY_FOR_PURCHASE = 'PayForPurchase';
export const CONFIRM_PURCHASE = 'ConfirmPurchase';
export const GET_USER_INVENTORY = 'GetUserInventory';
export const ADD_TO_USER_INVENTORY = 'addToUserInventory';
export const GET_USER_DATA = 'GetUserData';
export const UPDATE_USER_DATA = 'UpdateUserData';
export const GET_CATALOG_ITEMS = 'GetCatalogItems';
export const ACCOUNT_NOT_FOUND_ERRORS = ['AccountNotFound', 'User not found'];

export const NOT_APPROVED_USER_ERROR = {
  error: {
    code: 401,
    status: 'Unauthorized',
    error: 'hasNotBeenApproved',
    errorCode: 1143,
    errorMessage:
      'Please verify your account by clicking the link in your email.'
  }
};

export const USER_EXIST_ERROR = {
  error: {
    code: 401,
    status: 'Unauthorized',
    error: 'userExistError',
    errorCode: 1144,
    errorMessage: 'Email address is already registered.'
  }
};
