import { UserRole } from './enums/user-role';
import { Request } from './interfaces/request';
import { filter, Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ValueFormatterParams } from 'ag-grid-community';
import { roleDisplayNames } from './data/role-display-names';
import { Episode } from './enums/episode';

export const utils = (role: string | null, id: string | undefined) => {
  if ([UserRole.INDIVIDUAL, UserRole.STUDENT].includes(role as UserRole)) {
    return '/dashboard/game';
  } else if (role === UserRole.DEMO_PLAYER) {
    return '/dashboard/demo-game';
  }
  return `/dashboard/${role ?? ''}${id ? `/${id}` : ''}`;
};

export const filterRequestCode = (
  source$: Observable<Request<Object>>
): Observable<Request<Object>> =>
  source$.pipe(filter((v: Request<Object>) => v?.code === 200));

export const responseHandler = (toast: ToastrService) => (source$: any) =>
  source$.pipe(
    tap(({ data }) => {
      const message =
        data?.Logs?.[0]?.Data?.apiError?.errorMessage ||
        data?.Error?.Message ||
        data?.FunctionResult?.errorMessage;
      if (message) {
        toast.error(message);
        throw Error(message);
      }
      toast.success('Success');
    })
  );

export const isCorrectRole = (role: UserRole | null): boolean =>
  !!role &&
  (role === UserRole.ADMIN ||
    role === UserRole.INSTRUCTOR ||
    role === UserRole.INDIVIDUAL ||
    role === UserRole.DEMO_PLAYER ||
    role === UserRole.STUDENT);

const emailRegExp = new RegExp('^[^\\s@]+@([^\\s@.,]+\\.)+[^\\s@.,]{2,}$');
export const isEmailValid = (email: string): boolean =>
  emailRegExp.test(email?.trim());

export const handleStudentQuizData = (
  data: string | undefined
): Map<string, string[][]> => {
  const result = new Map<string, string[][]>();
  if (!data) {
    return result;
  }
  const quizData = JSON.parse(data);
  for (const item in quizData.QuizzesAnswers) {
    const temp = quizData.QuizzesAnswers[item];
    result.set(
      item,
      temp.map((value: string) => value.split(' : '))
    );
  }
  return result;
};

export const getRoleDisplayName = (
  valueFormatterParams: ValueFormatterParams<UserRole>
): string => roleDisplayNames[valueFormatterParams?.value];

const tracingQuizzes = ['Episode 1 Quiz', 'Macro Pre Quiz'];
export const calculateScore = (value: string): string => {
  if (!value) {
    return '0%';
  }
  const data: string[] = JSON.parse(value).Data;
  const obj: { [key: string]: number } = {};
  data.forEach((item: string) => {
    const keyValue: string[] = item.split(':');
    const percent = keyValue[1].replace('%', '').trim();
    if (tracingQuizzes.includes(keyValue[0]) && !Number.isNaN(+percent)) {
      obj[keyValue[0]] = +percent;
    }
  });
  const score =
    Object.values(obj).reduce((a: number, b: number) => a + b) /
    tracingQuizzes.length;
  return `${score}%`;
};

export const calculateProgress = (value: string, episode: Episode) => {
  if (!value) {
    return 0;
  }
  const data = JSON.parse(value).Data;
  return data[episode];
};

export const daysAfterShowLicenseExpiration = 14;

export const convertMillisecondsToDays = (milliseconds: number): number => {
  const oneDay = 86400000;
  return Math.floor(milliseconds / oneDay);
};

export const convertRMToUsd = (rm: number): number => rm / 100;
