<app-content-wrapper>
  <div
    class="flex flex-col justify-center flex-grow items-center bg-gray-100 w-full py-10"
  >
    <p class="text-2xl text-sky-600 font-bold mb-4">Reset Your Password</p>
    <span class="text-gray-500 mb-8">Create a new password below</span>
    <form (submit)="handleReset()" [formGroup]="formGroup" class="w-80 mb-16">
      <div class="flex items-start justify-between">
        <p class="text-sm text-gray-500">Password</p>
        <app-show-password-button
          (passwordTypeChange$)="passwordType = $event"
          *ngIf="formGroup.get('password')?.value"
        ></app-show-password-button>
      </div>
      <input
        [type]="passwordType"
        class="border rounded border-gray-400 p-2 w-full mb-8"
        formControlName="password"
      />
      <p class="text-sm text-start text-gray-500">Confirm New Password</p>
      <input
        [type]="passwordType"
        class="border rounded border-gray-400 p-2 w-full mb-8"
        formControlName="confirmPassword"
      />
      <app-loading-button
        [disabled]="!formGroup.valid || loading"
        [loading]="loading"
        text="Reset"
      ></app-loading-button>
    </form>
    <div class="w-[500px]">
      <p
        *ngIf="
          !requestErrorMessage && !(formGroup.controls | formErrorTransform);
          else errorWrapper
        "
        class="text-center text-xs border-2 border-transparent text-gray-400 mb-6 py-3 px-4 w-96 mx-auto"
      >
        Your password must include
      </p>
      <ng-template #errorWrapper>
        <p
          [class.invisible]="
            !formGroup.getError('notSame') &&
            !requestErrorMessage &&
            !(formGroup.controls | formErrorTransform)
          "
          class="py-3 px-4 mb-6 w-96 border-orange-300 border-2 text-orange-400 text-xs text-center mx-auto"
        >
          <ng-container
            *ngIf="formGroup.getError('notSame'); else formErrorMessages"
            >Passwords don't match. Try again
          </ng-container>
          <ng-template #formErrorMessages>
            {{
              requestErrorMessage || (formGroup.controls | formErrorTransform)
            }}
          </ng-template>
        </p>
      </ng-template>
      <div class="border border-gray-200 rounded py-6 pb-2">
        <ul class="grid grid-cols-2 px-10 text-left">
          <li
            *ngFor="let rule of passwordRules"
            class="list-disc mb-4 mx-8 text-xs text-gray-400"
          >
            {{ rule }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</app-content-wrapper>
