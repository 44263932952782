import { UserRole } from '../enums/user-role';

export const roleDisplayNames: { [key: string]: string } = {
  [UserRole.INSTRUCTOR]: 'Academic',
  [UserRole.INDIVIDUAL]: 'Individual',
  [UserRole.REJECTED_INSTRUCTORS]: 'Denied Academic',
  [UserRole.REJECTED_INDIVIDUALS]: 'Denied Individual',
  [UserRole.PENDING_APPROVAL_INSTRUCTORS]: 'Pending Academic',
  [UserRole.PENDING_APPROVAL_INDIVIDUALS]: 'Pending Individual'
};
