import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PurchaseService } from '../../services/purchase.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { CHECKOUT_CONFIG } from '../../common/data/consts';
import { catchError, finalize } from 'rxjs';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
@UntilDestroy()
export class PaymentStatusComponent implements OnInit, OnDestroy {
  public errorMessage?: string;
  public isLoading: boolean = false;

  constructor(
    private purchaseService: PurchaseService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    this.fetchPaymentStatus();
  }

  ngOnDestroy(): void {
    this.sessionStorageService.clearSessionStorage();
  }

  private fetchPaymentStatus(): void {
    const checkoutConfig: string | null =
      this.sessionStorageService.getItem(CHECKOUT_CONFIG);
    if (!checkoutConfig) return;
    this.isLoading = true;
    this.purchaseService
      .confirmPurchase(JSON.parse(checkoutConfig))
      .pipe(
        catchError((err) => {
          this.isLoading = false;
          if (err) {
            this.errorMessage =
              err.error.errorMessage ||
              err.errorMessage ||
              'Something went wrong';
          }
          throw err;
        }),
        finalize(() => (this.isLoading = false)),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
