<div
  class="flex-col bg-gray-100 bg-gradient-to-t from-cyan-100 from-cyan-200 h-full"
  *ngIf="userPlan"
>
  <app-header></app-header>

  <p class="text-2xl text-sky-600 font-bold mb-10 text-center m-10">
    Choose your plan
  </p>

  <div class="flex justify-center flex-wrap">
    <app-user-plan [plan]="userPlan[UserPlan.Individual]">
      <button
        class="btn-secondary mb-[76px] mt-auto"
        (click)="handleSignUpUser(UserType.Individual)"
      >
        Buy Now!
      </button>
      <!--      <p class="h-6 text-sky-400"></p>-->
    </app-user-plan>

    <app-user-plan [plan]="userPlan[UserPlan.Academic]">
      <button
        class="btn-secondary mb-3 mt-auto"
        (click)="handleSignUpUser(UserType.Teacher)"
      >
        Buy Now!
      </button>

      <div
        class="p-2 text-sky-400 text-center"
        *ngIf="userPlan[UserPlan.Academic]"
      >
        * Price is per student per academic year. <br />
        Minimum academic purchase is 3 seats. <br />
        Renews on July 1, 2025
      </div>
    </app-user-plan>
  </div>

  <div class="text-center text-gray-500 font-medium leading-6">
    Looking for School, District or Corporation pricing?
    <a
      class="text-sky-600 underline decoration-2"
      target="_blank"
      href="mailto:admin@beatthefed.com?subject=Beat the Fed special pricing request"
    >
      Request a quote!</a
    >
  </div>
</div>
