import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserType } from '../../../common/enums/user-type';

@Component({
  selector: 'app-role-type',
  templateUrl: './role-type.component.html',
  styleUrls: ['./role-type.component.scss']
})
export class RoleTypeComponent {
  @Input() public btnText: string = '';
  @Input() public userType?: UserType;
  @Output() public action$: EventEmitter<UserType> =
    new EventEmitter<UserType>();

  public UserType = UserType;
}
