<button
  [class.opacity-50]="disabled"
  [disabled]="disabled"
  (click)="handleAction()"
  class="py-3 px-16 min-w-min bg-gradient-to-t from-sky-600 to-blue-400 text-xl text-white relative"
  type="submit"
>
  <span *ngIf="loading" class="load loading"></span>
  {{ text }}
</button>
