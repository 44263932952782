import { Component, Input } from '@angular/core';
import { Plan } from '../../../common/interfaces/plan';
import { UserPlan } from '../../../common/enums/user-plan';

@Component({
  selector: 'app-user-plan',
  templateUrl: './user-plan.component.html',
  styleUrls: ['./user-plan.component.scss']
})
export class UserPlanComponent {
  @Input() public plan?: Plan;
  protected readonly UserPlan = UserPlan;
}
