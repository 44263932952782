<app-content-wrapper>
  <p class="text-lime-500 text-xl">Password Reset</p>
  <span class="text-sm text-gray-500 mb-10">
    Click the button below to login with your new password.
  </span>
  <button
    class="py-3 px-4 w-60 bg-gradient-to-t from-sky-600 to-blue-400 text-xl text-white"
    routerLink="/sign-in"
  >
    Login
  </button>
</app-content-wrapper>
