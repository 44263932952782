import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EXECUTE_CLOUD_SCRIPT } from '../common/data/consts';

@Injectable()
export class XSecretInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes(EXECUTE_CLOUD_SCRIPT)) {
      return next.handle(request);
    }
    return next.handle(
      request.clone({
        setHeaders: { 'X-SecretKey': environment.secretKey }
      })
    );
  }
}
