import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent {
  @Input() public loading?: boolean;
  @Input() public text = 'Click';
  @Input() public disabled?: boolean;
  @Output() public action$: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public handleAction() {
    if (!(this.loading && this.disabled)) {
      this.action$.emit();
    }
  }
}
