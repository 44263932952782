<div
  class="px-8 py-3 sticky top-0 bg-white h-[90px] border-b border-b-gray-400"
>
  <div class="flex justify-between items-center">
    <div [class.m-auto]="!userEmail">
      <img
        alt="Beat The Fed"
        [class.m-auto]="!userEmail"
        class="w-56 mt-1 cursor-pointer"
        src="../../../../assets/images/BeatTheFed.png"
        routerLink="/"
      />
    </div>

    <div *ngIf="userEmail" class="flex items-center gap-16 justify-end">
      <button
        *ngIf="!isGameMode && canPlay"
        class="py-3 px-4 w-40 bg-gradient-to-t from-sky-600 to-blue-400 text-md text-white"
        routerLink="/dashboard/game"
      >
        Launch Game!
      </button>
      <div class="flex items-center gap-2.5">
        <img
          alt="Beat The Fed"
          class="w-12 h-12"
          src="../../../../assets/images/Avatar.png"
        />
        <div class="flex flex-col justify-start">
          <p
            class="text-gray-600 text-md text-ellipsis overflow-hidden max-w-[300px]"
          >
            {{ userEmail }}
          </p>
          <a
            (click)="logOut()"
            class="text-sky-400 text-md hover:cursor-pointer"
            >Log out</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
