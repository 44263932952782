import { Directive, EventEmitter, Output } from '@angular/core';

@Directive()
export abstract class BasePopUpComponent<T> {
  public data: T | undefined;
  @Output() closeEvent = new EventEmitter();

  public handleClose(): void {
    this.closeEvent.emit();
  }
}
