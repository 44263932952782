<div class="grid grid-cols-2 sign-in-wrapper h-full w-full">
  <div class="w-full px-10 bg-gray-100 py-8 flex flex-col">
    <div class="w-full items-center justify-center">
      <div class="flex justify-center items-center py-4">
        <img
          alt="Beat The Fed"
          class="w-60"
          src="assets/images/BeatTheFed.png"
        />
      </div>

      <div class="flex flex-col justify-center my-8 items-center">
        <p class="text-2xl text-sky-600">Login</p>
        <div class="flex justify-center py-4">
          <span class="text-gray-500">Need an account?</span>
          <a class="text-sky-400 underline ml-2" routerLink="/pick-role"
            >Sign up!</a
          >
        </div>
      </div>

      <form (submit)="handleSignIn()" [formGroup]="formGroup">
        <div
          [class.error]="
            !formGroup.get('email')?.valid && formGroup.get('email')?.touched
          "
          class="my-4"
        >
          <div class="text-gray-500">Email</div>
          <input
            class="border rounded border-gray-400 p-2 w-full"
            formControlName="email"
            type="text"
          />
        </div>

        <div
          [class.error]="
            !formGroup.get('password')?.valid &&
            formGroup.get('password')?.touched
          "
          class="my-4"
        >
          <div class="flex items-center justify-between">
            <div class="text-gray-500">Password</div>
            <app-show-password-button
              (passwordTypeChange$)="passwordType = $event"
              *ngIf="formGroup.get('password')?.value"
            ></app-show-password-button>
          </div>
          <input
            [class.error]="
              !formGroup.get('password')?.valid &&
              formGroup.get('password')?.touched
            "
            [type]="passwordType"
            autocomplete="password"
            class="border rounded border-gray-400 p-2 w-full"
            formControlName="password"
          />
        </div>

        <div class="flex justify-center items-center w-full">
          <app-loading-button
            [disabled]="!formGroup.valid || loading"
            [loading]="loading"
            text="Log in"
          ></app-loading-button>
        </div>

        <div class="flex justify-center items-center w-full flex-col">
          <a
            (click)="handleForgot()"
            class="text-sky-400 ml-2 hover:cursor-pointer"
            >Forgot password ?</a
          >
          <div
            [class.invisible]="
              !requestErrorMessage && !(formGroup.controls | formErrorTransform)
            "
            class="py-3 mt-16 px-4 w-72 border-orange-300 bg-white border-2 text-orange-400 text-x text-center"
          >
            {{
              requestErrorMessage || (formGroup.controls | formErrorTransform)
            }}
          </div>
        </div>
      </form>
    </div>
    <div class="mt-auto">
      Please review our
      <a class="text-sky-400 cursor-pointer" routerLink="/privacy-policy">
        Privacy Policy</a
      >
      and
      <a class="text-sky-400 cursor-pointer" routerLink="/terms-of-service">
        Terms of Use</a
      >.
    </div>
  </div>

  <div
    class="col-start-2 col-end-3 bg-cover bg-top bg-[url('assets/images/bg_lab.png')]"
  ></div>
</div>
