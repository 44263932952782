import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  ACCOUNT_INFO,
  ADD_OR_UPDATE_EMAIL,
  CLIENT,
  EXECUTE_CLOUD_SCRIPT,
  GET_USER_DATA,
  GET_USER_GROUP,
  GET_USER_ROLES,
  ROLE,
  UPDATE_USER_DATA,
  USER
} from '../common/data/consts';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Entity } from '../common/interfaces/class-group';
import { Request } from '../common/interfaces/request';
import { AccountInfo, GroupRole, User } from '../common/interfaces/user';
import { UserRole } from '../common/enums/user-role';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) {}

  public getUser(): User | null {
    const user = this.localStorage.getItem(USER);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public getUserRole(): UserRole | null {
    return this.localStorage.getItem(ROLE) as UserRole;
  }

  public getAccountInfo(): Observable<Request<{ AccountInfo: AccountInfo }>> {
    return this.httpClient
      .post<Request<{ AccountInfo: AccountInfo }>>(
        `${environment.baseApi}/${CLIENT}/${ACCOUNT_INFO}`,
        {}
      )
      .pipe(
        tap(({ data }) => {
          const account = data.AccountInfo;
          this.localStorage.setItem('PlayFabId', account.PlayFabId);
          this.localStorage.setItem(
            USER,
            JSON.stringify({
              created: account.Created,
              email: account.PrivateInfo.Email,
              firstLogin: account.TitleInfo.FirstLogin,
              lastLogin: account.TitleInfo.LastLogin,
              accountId: account.TitleInfo.TitlePlayerAccount.Id
            })
          );
        })
      );
  }

  public getUserGroup(): Observable<Entity> {
    return this.httpClient
      .post<Request<{ FunctionResult: Entity }>>(
        `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
        {
          FunctionName: GET_USER_GROUP,
          FunctionParameter: {}
        }
      )
      .pipe(
        catchError((error) => throwError(error)),
        map(({ data }) => data.FunctionResult)
      );
  }

  public getUserRoles(entity: Entity): Observable<GroupRole[]> {
    return this.httpClient
      .post<Request<{ FunctionResult: GroupRole[] }>>(
        `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
        {
          FunctionName: GET_USER_ROLES,
          FunctionParameter: { Entity: entity }
        }
      )
      .pipe(map(({ data }) => data.FunctionResult));
  }

  public addOrUpdateUserEmail(
    EmailAddress: string
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${CLIENT}/${ADD_OR_UPDATE_EMAIL}`,
      {
        EmailAddress
      }
    );
  }

  public getUserData(): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${CLIENT}/${GET_USER_DATA}`,
      {}
    );
  }

  public updateUserData(data: object): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${CLIENT}/${UPDATE_USER_DATA}`,
      {
        Data: data
      }
    );
  }
}
