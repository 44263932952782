<app-header></app-header>

<div class="flex justify-center items-center flex-col bg-gray-100 py-8">
  <p class="text-2xl text-sky-600 font-bold">Welcome To Beat The Fed!</p>
  <p class="text-gray-500">
    First, tell us if you're a student or an instructor
  </p>
</div>

<div class="flex w-full h-full sm:h-auto">
  <app-role-type
    (action$)="handleSignUpUser($event)"
    [btnText]="studentBtnText"
    [userType]="UserType.Student"
  ></app-role-type>

  <app-role-type
    (action$)="handleSignUpUser($event)"
    [btnText]="teacherBtnText"
    [userType]="UserType.Teacher"
  ></app-role-type>
</div>

<div class="flex justify-center mt-8 mb-2">
  <span class="text-gray-500">Already have an account ?</span>
  <a class="text-sky-400 underline ml-2" routerLink="/sign-in">Log in</a>
</div>

<div class="flex justify-center pb-4">
  <span class="text-gray-500">Not a student or a teacher? </span>
  <a
    class="text-sky-400 underline ml-2"
    routerLink="/sign-up"
    [queryParams]="{ userType: 'Individual' }"
    >Sign up here</a
  >
</div>
