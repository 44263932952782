import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { BasePopUpComponent } from '../base-pop-up/base-pop-up.component';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrls: ['./information-popup.component.scss'],
  standalone: true
})
export class InformationPopupComponent extends BasePopUpComponent<any> {
  @Input() public override data: any;
  @Output() confirmEvent = new EventEmitter();
  public adminService: AdminService = inject(AdminService);

  constructor() {
    super();
  }

  public override handleClose() {
    super.handleClose();
  }
}
