import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import {
  ACCOUNT_INFO,
  ENTITY_TOKEN,
  EXECUTE_CLOUD_SCRIPT
} from '../common/data/consts';
import { LocalStorageService } from '../services/local-storage.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class XTokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorage: LocalStorageService,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.localStorage.getItem(ENTITY_TOKEN);
    const setToken =
      !request.url.includes(EXECUTE_CLOUD_SCRIPT) &&
      !request.url.includes(ACCOUNT_INFO);
    return next
      .handle(
        token && setToken
          ? request.clone({
              setHeaders: {
                'X-EntityToken': token
              }
            })
          : request
      )
      .pipe(
        catchError((err) => {
          if (err?.code === 401 && token) {
            this.authService.signOut();
            return throwError(err);
          }
          return throwError(err);
        })
      );
  }
}
