import { Component } from '@angular/core';
import { UserType } from 'src/app/common/enums/user-type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pick-role-type',
  templateUrl: './pick-role-type.component.html',
  styleUrls: ['./pick-role-type.component.scss']
})
export class PickRoleTypeComponent {
  public teacherBtnText = 'Create your class';
  public studentBtnText = 'Join your class';

  public UserType = UserType;

  constructor(private router: Router) {}

  public handleSignUpUser(userType: UserType): void {
    this.router.navigate(['/sign-up'], {
      queryParams: {
        userType
      }
    });
  }
}
