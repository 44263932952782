import { Injectable } from '@angular/core';
import { filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { Request } from '../common/interfaces/request';
import { environment } from '../../environments/environment';
import {
  ADMIN,
  CHANGE_MEMBER_ROLE,
  CHANGE_PLAYER_ROLE,
  CLIENT,
  DELETE_GROUP,
  DELETE_MASTER_PLAYER_ACCOUNT,
  DELETE_PLAYER,
  EXECUTE_CLOUD_SCRIPT,
  GET_MEMBERS_WITH_ROLE_IDS,
  GET_PROFILE_INFO,
  GROUP,
  REMOVE_MEMBERS
} from '../common/data/consts';
import { HttpClient } from '@angular/common/http';
import { UserRole, UserStatus } from '../common/enums/user-role';
import { ListGroupMembers, Members, UserData } from '../common/interfaces/user';
import { Entity, GroupMember } from '../common/interfaces/class-group';
import { ToastrService } from 'ngx-toastr';
import { responseHandler } from '../common/utils';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private httpClient: HttpClient, private toast: ToastrService) {}

  public getGroupWithRoleIdsList(
    RoleIds: UserRole[],
    Group: Entity
  ): Observable<ListGroupMembers[]> {
    return this.httpClient
      .post<Request<{ FunctionResult: ListGroupMembers[] }>>(
        `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
        {
          FunctionName: GET_MEMBERS_WITH_ROLE_IDS,
          FunctionParameter: { RoleIds, Group }
        }
      )
      .pipe(map(({ data }) => data.FunctionResult));
  }

  public getPlayerProfile(member: Members): Observable<UserData> {
    return this.httpClient
      .post<
        Request<{
          FunctionResult: { Data: UserData; Created: { Value: string } };
        }>
      >(`${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`, {
        FunctionName: GET_PROFILE_INFO,
        FunctionParameter: {
          PlayFabId: member.Lineage.master_player_account.Id
        }
      })
      .pipe(
        map(({ data }) => ({
          ...data.FunctionResult.Data,
          Created: data.FunctionResult.Created,
          roleId: { Value: member.RoleId as UserRole },
          key: member.Key
        }))
      );
  }

  public updatePlayerRole(
    Group: Entity,
    Members: Entity[],
    OriginRoleId: UserRole,
    DestinationRoleId: UserStatus
  ): Observable<Request<Object>> {
    return this.httpClient
      .post<Request<Object>>(
        `${environment.baseApi}/${GROUP}/${CHANGE_MEMBER_ROLE}`,
        { Group, Members, OriginRoleId, DestinationRoleId }
      )
      .pipe(responseHandler(this.toast));
  }

  public changePlayerRole(
    Group: Entity,
    Members: Entity[],
    OriginRoleId: UserRole,
    DestinationRoleId: UserStatus
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<{ FunctionResult: GroupMember[] }>>(
      `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
      {
        FunctionName: CHANGE_PLAYER_ROLE,
        FunctionParameter: { Group, Members, OriginRoleId, DestinationRoleId }
      }
    );
  }

  public deletePlayerAccount(
    masterPlayerAccountId: string
  ): Observable<Request<Object>> {
    return this.deleteTitlePlayerAccount(masterPlayerAccountId).pipe(
      switchMap(() => this.deleteMasterPlayerAccount(masterPlayerAccountId))
    );
  }

  public deleteMasterPlayerAccount(
    playFabId: string
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${ADMIN}/${DELETE_MASTER_PLAYER_ACCOUNT}`,
      { PlayFabId: playFabId }
    );
  }

  public deleteTitlePlayerAccount(
    playFabId: string
  ): Observable<Request<Object>> {
    return this.httpClient.post<Request<Object>>(
      `${environment.baseApi}/${ADMIN}/${DELETE_PLAYER}`,
      { PlayFabId: playFabId }
    );
  }

  public deleteGroup(group: Entity): Observable<Request<Object>> {
    return this.httpClient.post<Request<{ FunctionResult: GroupMember[] }>>(
      `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
      {
        FunctionName: DELETE_GROUP,
        FunctionParameter: { Group: group }
      }
    );
  }

  public removeMembers(
    Group: Entity,
    Members: Entity[]
  ): Observable<{ teacher: Members; group: Entity }> {
    const batchSize = 5;

    const removeMembersRequest$ = this.httpClient.post<
      Request<{
        FunctionResult: {
          students: Members[];
          teachers: Members[];
          group: Entity;
        };
      }>
    >(`${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`, {
      FunctionName: REMOVE_MEMBERS,
      FunctionParameter: { Group, Members }
    });

    return removeMembersRequest$.pipe(
      filter(({ data }) => !!data?.FunctionResult),
      switchMap(({ data }) => {
        const { students, teachers, group } = data.FunctionResult;
        const deleteBatch = (
          batch: Members[],
          index: number
        ): Observable<{ teacher: Members; group: Entity }> => {
          const batchIds = batch.slice(index, index + batchSize);
          if (batchIds.length === 0) {
            return of({ teacher: teachers[0], group });
          }
          return forkJoin(
            batchIds.map((member: Members) =>
              this.deletePlayerAccount(member.Lineage.master_player_account.Id)
            )
          ).pipe(switchMap(() => deleteBatch(batch, index + batchSize)));
        };

        return deleteBatch(students, 0);
      })
    );
  }
}
