<div class="fixed w-full h-full left-0 top-0 z-50 bg-black bg-opacity-30">
  <div
    class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-20 w-[500px] border border-white bg-white"
  >
    <div class="relative flex p-2 text-center items-center bg-sky-600">
      <p class="text-center text-white w-full">
        {{ data.title }}
      </p>
    </div>
    <div class="flex flex-col justify-center items-center p-4 gap-5">
      <div class="text-center">
        <p
          class="font-bold"
          [class]="data.title === 'Error' ? 'text-red-700' : 'text-sky-500'"
        >
          {{ data.body }}
        </p>
      </div>
      <div class="flex justify-center items-center gap-2 w-full">
        <button
          (click)="handleClose()"
          class="shadow-lg border-2 bg-gradient-to-b from-white to-gray-200 text-amber-600 px-12 xy-2"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
