import { UserPlan } from '../enums/user-plan';
import { UserRole } from '../enums/user-role';

export const classSeatsId = 'classSeats';
export const individualSeatId = 'seat-individual';
export const academicSeatId = 'seat-academic';
export const stocktrakSeatId = 'seat-stocktrak';

export const publishableKey = {
  name: 'publishable-key',
  value:
    'pk_live_51PO4Jp2L23uZdPZqKmIWyk8lh6qhJVVARGHwzBoceQeo9e9lhJ6zIeZzI5VkihG1IJM7yea5dyEN0ndE0CjDQYHo00E9m8yT4i'
};
export const individualStripeButton = {
  name: 'buy-button-id',
  value: 'buy_btn_1PT6Oa2L23uZdPZqdegB5xcL'
  // value: 'buy_btn_1QVDeH2L23uZdPZq288fXj9o'
};

export const academicStripeButton = {
  name: 'buy-button-id',
  value: 'buy_btn_1PTi882L23uZdPZqafADt3R4'
};

export const seatStripeButton = {
  name: 'buy-button-id',
  value: 'buy_btn_1Pbdr42L23uZdPZqBz2dmrd2'
};

export const academicRoles = [
  UserRole.INSTRUCTOR,
  UserRole.PENDING_APPROVAL_INSTRUCTORS,
  UserRole.REJECTED_INSTRUCTORS
];

export const choosePlans = {
  [UserPlan.Individual]: {
    name: UserPlan.Individual,
    qty: 1,
    price: 0,
    benefits: [
      'Access to FULL GAME',
      'Free game updates',
      'Global leaderboards',
      'Glossary',
      'Game progress saved automatically'
    ]
  },
  [UserPlan.Academic]: {
    name: UserPlan.Academic,
    qty: 3,
    price: 0,
    benefits: ['Teacher portal', 'Class lists', 'Student progress tracking']
  }
};

export const individualPaymentLLink =
  'https://buy.stripe.com/5kAdUSdlW1KB6Qg3cc';
export const academicPaymentLLink = 'https://buy.stripe.com/dR66sq95G1KBb6w9AB';
