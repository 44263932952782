import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  CLIENT,
  CREATE_GROUP,
  EXECUTE_CLOUD_SCRIPT,
  GET_GROUP,
  GET_TITLE_MEMBERSHIP,
  GROUP,
  UPDATE_GROUP
} from '../common/data/consts';
import {
  catchError,
  map,
  Observable,
  shareReplay,
  switchMap,
  throwError,
  zip
} from 'rxjs';
import {
  ClassGroup,
  Entity,
  GroupMember
} from '../common/interfaces/class-group';
import { Request } from '../common/interfaces/request';
import { ToastrService } from 'ngx-toastr';
import { responseHandler } from '../common/utils';
import { UserRole } from '../common/enums/user-role';
import { ListGroupMembers, Members, UserData } from '../common/interfaces/user';
import { AdminService } from './admin.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ClassesService {
  constructor(
    private httpClient: HttpClient,
    private toast: ToastrService,
    private adminService: AdminService,
    private userService: UserService
  ) {}

  public createClass(groupName: string, group: Entity): Observable<ClassGroup> {
    return this.httpClient
      .post<
        Request<{ FunctionResult: ClassGroup; Error: { Message: string } }>
      >(`${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`, {
        FunctionName: CREATE_GROUP,
        FunctionParameter: { Entity: group, GroupName: groupName }
      })
      .pipe(
        responseHandler(this.toast),
        map(({ data }) => (data as any).FunctionResult)
      );
  }

  public updateClass(
    group: Entity,
    groupName: string,
    entity: Entity
  ): Observable<Request<Object>> {
    return this.httpClient
      .post<
        Request<{ FunctionResult: ClassGroup; Error: { Message: string } }>
      >(`${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`, {
        FunctionName: UPDATE_GROUP,
        FunctionParameter: {
          Group: group,
          GroupName: groupName,
          Entity: entity
        }
      })
      .pipe(responseHandler(this.toast));
  }

  public getTeacherClass(group: Entity): Observable<GroupMember> {
    return this.httpClient
      .post<Request<{ FunctionResult: GroupMember[] }>>(
        `${environment.baseApi}/${CLIENT}/${EXECUTE_CLOUD_SCRIPT}`,
        {
          FunctionName: GET_TITLE_MEMBERSHIP,
          FunctionParameter: { Entity: group }
        }
      )
      .pipe(
        shareReplay(1),
        map(({ data }) => data.FunctionResult?.[0])
      );
  }

  public getClass(group: Entity): Observable<Request<ClassGroup>> {
    return this.httpClient.post<Request<ClassGroup>>(
      `${environment.baseApi}/${GROUP}/${GET_GROUP}`,
      { Group: group }
    );
  }

  public getGroupMembersProfiles(members: Members[]): Observable<UserData[]> {
    return zip(
      members.map((member: Members) =>
        this.adminService.getPlayerProfile(member)
      )
    ).pipe(
      map((usersData: UserData[]) =>
        usersData.filter((userData: UserData) =>
          Object.keys(userData).includes('email')
        )
      )
    );
  }

  public getGroupMembership(): Observable<Members[]> {
    const pendingRoles = [
      UserRole.PENDING_APPROVAL_INDIVIDUALS,
      UserRole.PENDING_APPROVAL_INSTRUCTORS
    ];
    return this.userService.getUserGroup().pipe(
      switchMap((group) =>
        this.adminService.getGroupWithRoleIdsList(
          [
            UserRole.PENDING_APPROVAL_INDIVIDUALS,
            UserRole.PENDING_APPROVAL_INSTRUCTORS,
            UserRole.INSTRUCTOR,
            UserRole.INDIVIDUAL,
            UserRole.REJECTED_INSTRUCTORS,
            UserRole.REJECTED_INDIVIDUALS
          ],
          group
        )
      ),
      catchError((error) => throwError(error)),
      map((listGroup) => {
        const group = listGroup.sort((a, _) =>
          pendingRoles.includes(a.RoleId as UserRole) ? -1 : 1
        );
        return group.flatMap((group: ListGroupMembers) =>
          group.Members.map((member) => ({
            ...member,
            RoleId: group.RoleId
          }))
        );
      })
    );
  }
}
