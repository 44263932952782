import { Pipe, PipeTransform } from '@angular/core';
import { UserType } from '../enums/user-type';

@Pipe({
  name: 'userTypeTransform',
  standalone: true,
  pure: false
})
export class UserTypeTransformPipe implements PipeTransform {
  transform(userType: UserType): string {
    switch (userType) {
      case UserType.Teacher:
        return UserType.Student;
      case UserType.Student:
      case UserType.Individual:
        return UserType.Teacher;
      case UserType.DemoPlayer:
        return '';
    }
  }
}
