import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-show-password-button',
  templateUrl: './show-password-button.component.html',
  styleUrls: ['./show-password-button.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ShowPasswordButtonComponent {
  @Input() public currentType = ActionType.Password;

  @Output() public passwordTypeChange$: EventEmitter<string> =
    new EventEmitter<string>();

  public action = {
    text: 'Hide',
    password: 'Show'
  };

  public ActionType = ActionType;

  public changeType(): void {
    this.currentType =
      this.currentType === ActionType.Text
        ? ActionType.Password
        : ActionType.Text;
    this.passwordTypeChange$.emit(this.currentType);
  }
}

enum ActionType {
  Text = 'text',
  Password = 'password'
}
