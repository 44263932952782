export enum UserRole {
  ADMIN = 'admins',
  INSTRUCTOR = 'instructors',
  INDIVIDUAL = 'individuals',
  DEMO_PLAYER = 'demo-player',
  STUDENT = 'students',
  PENDING_APPROVAL_INSTRUCTORS = 'pending-approval-instructors',
  PENDING_APPROVAL_INDIVIDUALS = 'pending-approval-individuals',
  REJECTED_INSTRUCTORS = 'rejected-instructors',
  REJECTED_INDIVIDUALS = 'rejected-individuals',
  MEMBERS = 'members'
}

export enum UserStatus {
  REMOVED = 'removed',
  REJECTED_INSTRUCTORS = 'rejected-instructors',
  REJECTED_INDIVIDUALS = 'rejected-individuals',
  Instructor = 'instructors',
  Individuals = 'individuals'
}
