import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { LocalStorageService } from '../../../services/local-storage.service';
import { USER } from '../../../common/data/consts';
import { CommonModule } from '@angular/common';
import { UserRole } from '../../../common/enums/user-role';
import { UserService } from '../../../services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InventoryService } from '../../../services/inventory.service';
import { ClickOutsideDirective } from '../../../common/directives/click-outside.directive';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [RouterLink, CommonModule, ClickOutsideDirective],
  styleUrls: ['./header.component.scss']
})
@UntilDestroy()
export class HeaderComponent implements OnInit {
  @Input() public isGameMode: boolean = false;
  public userEmail?: string;
  public canPlay: boolean = false;
  public isOpen: boolean = false;
  public canChangePlan: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private inventoryService: InventoryService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    const user: string | null = this.localStorageService.getItem(USER);
    if (user) this.userEmail = JSON.parse(user).email;
    this.getCanPlay();
    this.canChangePlan = this.userService.fetchCanChangeRole();
  }

  public logOut(): void {
    this.localStorageService.clearLocalStorage();
    this.router.navigateByUrl('');
  }

  private getCanPlay(): void {
    const userRole: UserRole | null = this.userService.getUserRole();
    if (!userRole) {
      this.canPlay = false;
      return;
    }
    if (userRole === UserRole.ADMIN) {
      this.canPlay = true;
      return;
    }
    this.inventoryService
      .hasSeats(userRole as UserRole)
      .pipe(untilDestroyed(this))
      .subscribe((canPlay: boolean) => (this.canPlay = canPlay));
  }

  public toggleAccount(): void {
    this.isOpen = !this.isOpen;
  }

  public changeAccountType(): void {
    this.router.navigateByUrl('/dashboard/change-plan');
  }
}
