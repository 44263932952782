import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import {
  ACCOUNT_NOT_FOUND_ERRORS,
  SESSION_TICKET
} from '../common/data/consts';
import { LocalStorageService } from '../services/local-storage.service';
import { Router } from '@angular/router';

@Injectable()
export class XAuthorization implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const sessionTicket = this.localStorageService.getItem(SESSION_TICKET);

    const modifiedRequest = sessionTicket
      ? request.clone({
          setHeaders: {
            'X-Authorization': sessionTicket
          }
        })
      : request;

    return next.handle(modifiedRequest).pipe(
      catchError(({ error }) => {
        if (
          sessionTicket &&
          error?.code === 400 &&
          ACCOUNT_NOT_FOUND_ERRORS.includes(error.error)
        ) {
          this.localStorageService.clearLocalStorage();
          this.router.navigateByUrl('');
        }
        return throwError(error);
      })
    );
  }
}
