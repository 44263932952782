<app-content-wrapper>
  <ng-container *ngIf="!resetClicked; else resendContainer">
    <p class="text-2xl text-sky-600 font-bold">Reset Your Password</p>
    <p class="text-gray-500">
      Enter the email you signed up with and we'll send Instructions for
      resetting your password.<br />
      This process may take up to 5 minutes.
    </p>
    <div class="flex flex-col justify-center items-center mt-16">
      <p class="text-sm text-gray-500 mr-auto mb-4">Confirm Your Email</p>
      <input
        [formControl]="confirmEmailFormControl"
        class="border rounded border-gray-400 p-2 w-80 mb-8"
        type="text"
      />
      <app-loading-button
        [disabled]="!confirmEmailFormControl.valid"
        [loading]="loading"
        text="Reset"
        (action$)="handleReset()"
      ></app-loading-button>
    </div>
  </ng-container>

  <ng-template #resendContainer>
    <p class="mb-4 text-lime-500 text-xl">Password Recovery Sent</p>
    <span>
      Check your email and follow the instructions to reset your password.
    </span>
    <button
      (click)="handleReset()"
      class="py-3 px-4 w-60 mt-8 bg-gradient-to-t from-sky-600 to-blue-400 text-xl text-white"
    >
      Resend
    </button>
  </ng-template>
</app-content-wrapper>
