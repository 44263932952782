<app-header></app-header>

<div class="container mx-auto my-8 p-8 bg-white rounded shadow-md">
  <section class="mb-6">
    <h1 class="text-3xl font-bold mb-6">Beat the Fed Game Terms of Use</h1>
    <p class="text-sm">Updated and Effective: 12/4/2023</p>
  </section>

  <section id="introduction" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Introduction and Applicability</h2>
    <p>
      These Terms of Use (the “TOU”) apply to the Beat the Fed Game, owned and
      operated by TideMill Group (“TideMill Group,” “we,” “us”, and “our”), and
      available at
      <a href="https://beatthefed.com/" class="text-blue-500 underline"
        >https://beatthefed.com/</a
      >
      (the “Game”), and govern your use of the Game.
    </p>
    <p>
      The TideMill Group makes the Game available to educational institutions
      and organizations (each, an “Institution”), their educators (“Teachers”)
      and students currently enrolled in the Institution (“Students”) pursuant
      to these Terms, which form a binding legal agreement between you and us.
      In these TOU, we refer to Teachers and Students collectively as “you,”
      “your” or “Users.”
    </p>
    <p>
      If you have entered into these Terms of Use on behalf of an Institution,
      including as an individual employed by an Institution, you represent that
      you have the necessary authority to bind that Institution or entity to the
      Agreement. If you do not agree to these Terms of Use or if you do not have
      the necessary authority to enter this agreement with us, you are
      prohibited from viewing, accessing, or using the Game.
    </p>
  </section>

  <section id="representations" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Your Representations</h2>
    <p>
      You represent that you (a) are of a legal age sufficient to agree to these
      TOU in accordance with applicable law, (b) have read, understood, and
      agree to be bound by these TOU, (c) where you are acting on behalf of an
      Institution, you have the authority to bind your Institution and your
      Institution’s Teachers and Students to these TOU; and (c) have all rights,
      permissions, and authorizations necessary to upload and provide for use to
      use as contemplated under these TOU all personal information as defined
      under applicable law; (e) have all rights, permissions, and authorizations
      necessary to upload and provide for us to use as contemplated under these
      TOU all documents, graphics, content, and other materials uploaded by you
      to the Game or that you provide on behalf of yourself and, where
      applicable, your Teachers and Students (collectively, “Content”).
    </p>
  </section>

  <section id="intellectual-property" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Intellectual Property</h2>
    <p>
      You acknowledge that: (i) the Game, including all trademarks, Game marks,
      logos, documents, graphics, content, and/or other materials viewed or
      obtained from or through the Game (collectively, “Game Materials”), are
      owned and/or licensed by TideMill Group and are protected by copyright and
      other intellectual property rights, and (ii) you have no rights to
      transfer, reproduce, or prepare any derivative works with respect to the
      Game Materials, or to disclose confidential information pertaining to, the
      Game or Game Materials. These TOU do not convey any right of ownership in
      or related to the Game, Game Materials, or other intellectual property
      owned by TideMill Group.
    </p>
  </section>

  <section id="account-setup" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Account Set-Up and Credentials</h2>
    <p>
      You are solely responsible for using the Game in accordance with these
      TOU, and for all activity that occurs under your account or by use of your
      User name, password, and other access credentials. You shall (i) maintain
      the confidentiality of your access credentials and not share with anybody;
      and (ii) promptly notify TideMill Group if you know or suspect that your
      access credentials have been compromised or a security breach of our Game
      or your account has occurred. You represent and warrant that any and all
      information provided to establish your account is true and accurate and
      that you will promptly update that information as may be necessary in the
      event of any change.
    </p>
  </section>

  <section id="license" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">License</h2>
    <p>
      You do hereby grant us a non-exclusive, perpetual, royalty-free, fully
      paid-up worldwide right and license to use the Content to furnish the
      Game. Except for the licenses granted in these TOU, nothing contained in
      these TOU shall be construed as granting TideMill Group any right, title,
      or interest in your Content.
    </p>
  </section>

  <section id="use-of-game" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Use of the Game</h2>
    <p>
      The Game is provided solely for your educational, non-commercial purposes.
      Any unauthorized use, including without limitation, any commercial use, is
      prohibited. You agree not to use the Game or Game Materials for any
      purpose that is unlawful or prohibited by these TOU, or any other purpose
      not reasonably intended by TideMill Group. You further agree to abide by
      all applicable laws and regulations, and in addition, you will not, nor
      will you allow a third party to:
    </p>
    <ul class="list-disc ml-6">
      <li>Upload, post, e-mail or otherwise transmit any Content that:</li>
      <ul class="list-disc ml-6">
        <li>
          is unlawful, harmful, threatening, intimidating, abusive, harassing,
          tortuous, defamatory, derogatory, vulgar, obscene, libelous, invasive
          of another’s privacy, disrespectful, hateful, or racially, ethnically
          or otherwise objectionable;
        </li>
        <li>
          you do not have the right to transmit under applicable law or under
          contractual or fiduciary relationships;
        </li>
        <li>
          infringes any patent, trademark, trade secret, copyright or other
          proprietary rights of any party;
        </li>
        <li>
          contains software viruses or worms or any other computer code, files
          or programs designed to disable, interrupt, destroy, redirect, monitor
          another user’s usage, limit or otherwise inhibit the functionality of
          any computer software or hardware or telecommunications equipment;
        </li>
      </ul>
      <li>
        not assign, sublicense, rent, timeshare, loan, lease or otherwise
        transfer the Game, or directly or directly grant access to or permit any
        unauthorized party to access or use the Game or Game Materials;
      </li>
      <li>
        decompile, disassemble, reverse engineer, copy, modify or create
        derivative works from the Game;
      </li>
      <li>
        impersonate any person or entity, including another User, or provide
        false information to gain access to the Game;
      </li>
      <li>
        view or attempt to view records or Content submitted by another End
        User;
      </li>
      <li>
        use automated systems (e.g., robots, spiders, etc.) to access the Game;
      </li>
      <li>
        Forge headers or otherwise manipulate identifiers in order to disguise
        the origin of any Content on or transmitted through the Game;
      </li>
      <li>
        discuss or incite illegal activity or solicit/post sexually explicit
        images;
      </li>
      <li>
        Interfere with or disrupt the Game or servers or networks connected to
        the Game, or disobey any requirements, procedures, policies or
        regulations of networks connected to the Game; or
      </li>
      <li>
        Harvest, scrape, programmatically crawl, or collect any Website
        Materials.
      </li>
      <li>
        not remove any proprietary notices (e.g., copyright and trademark
        notices) from either the Game or Game Materials.
      </li>
    </ul>
  </section>

  <section id="privacy-policy" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Privacy</h2>
    <p>
      We maintain a Privacy Policy, which may be viewed at
      <a
        href="https://beatthefed.com/privacy-policy"
        class="text-blue-500 underline"
        >https://beatthefed.com/privacy-policy</a
      >. We reserve the right to modify the Privacy Policy in accordance with
      the procedure outlined in that policy.
    </p>
  </section>

  <section id="changes-to-game" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Changes to the Game</h2>
    <p>
      We may, in our sole discretion, add, change, discontinue or remove any
      portion or functionality of a Game at any time, or the Game as a whole,
      without notice.
    </p>
  </section>

  <section id="ferpa" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">
      Family Educational Rights and Privacy Act (FERPA)
    </h2>
    <p>
      In the event that you represent an Institution subject to the Family
      Educational Rights and Privacy Act (FERPA), you determine that TideMill
      Group has a “legitimate educational interest” in providing the Game in
      performance of these TOU, and agree to designate TideMill Group as a
      "school official" as the terms are used in FERPA 34 CFR Section
      99.31(a)(1). TideMill Group shall remain under the direct control of the
      Institution with respect to the use and maintenance of FERPA-protected
      records and shall use Student Personal Information only to provide the
      Game as described in these Terms of Use and any contract we may have with
      the Institution, in accordance with applicable law.
    </p>
  </section>

  <section id="children-under-13" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Children Under Age 13</h2>
    <p>
      The Game is not intended for use by children under age 13, and we do not
      knowingly collect Personal Information from children. Users are prohibited
      from permitting an individual under age 13 from providing access to the
      Game. Should we learn that we have inadvertently collected Personal
      Information from a child, we will take steps to promptly delete it.
    </p>
  </section>

  <section id="feedback" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Feedback</h2>
    <p>
      You may provide us with Content that constitutes suggestions, comments, or
      other feedback about the Game (“Feedback”). Feedback is voluntary. We may
      use Feedback for any purpose without obligation of any kind in connection
      with our business, including for the purpose of enhancing the Game.
    </p>
  </section>

  <section id="links" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Links</h2>
    <p>
      The Game may contain links allowing you to go to websites or other online
      Game that are not under our control (“Linked Sites”). Any such links are
      provided for your convenience, and inclusion of links in the Game does not
      suggest any association, sponsorship, or endorsement of those Linked
      Sites. We are not responsible for the contents or transmission of any
      Linked Site or for ensuring that the Linked Sites are error and/or
      virus-free. Linked Sites may be subject to their own terms of use and
      privacy policies, and we encourage you to read them.
    </p>
  </section>

  <section id="suspension-removal" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">
      Suspension of Game and Removal of Content
    </h2>
    <p>
      We may, in our sole discretion, suspend your access to the Game or remove
      any Content from the Game for any of the following reasons, without
      limitation: (i) to prevent damages to, or degradation of, the Game; (ii)
      to comply with any law, regulation, court order, or other governmental
      request; and (iii) to otherwise protect us from potential legal liability.
      We will restore access to the Game as soon as practicable after the event
      giving rise to suspension has been resolved. This Section will not be
      construed as imposing any obligation or duty on us to monitor use of the
      Game.
    </p>
  </section>

  <section id="liabilities" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Limitation of Liabilities</h2>
    <p>
      IN ALL CASES, NEITHER TIDEMILL GROUP NOR ITS SUPPLIERS NOR DISTRIBUTORS
      ASSUME ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS ON THE
      GAME OR WITH RESPECT TO GAME MATERIALS, ANY FAILURES, DELAYS OR
      INTERRUPTIONS IN THE GAME’ ACCESSIBILITY, ANY LOSSES OR DAMAGES ARISING
      FROM THE USE OF THE GAME OR GAME MATERIALS, ANY CONDUCT BY OTHER END
      USERS, OR UNAUTHORIZED ACCESS TO OR USE OF THE GAME, GAME MATERIALS OR
      SERVERS
    </p>
  </section>

  <section id="warranties" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Disclaimer of Warranties</h2>
    <p>
      OTHER THAN AS EXPRESSLY SET OUT IN THE AGREEMENT, THE GAME AND GAME
      MATERIALS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL
      FAULTS, AND WITHOUT WARRANTY OF ANY KIND. TIDEMILL GROUP, ITS SUPPLIERS
      AND DISTRIBUTORS SPECIFICALLY DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER
      EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES
      OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT,
      QUALITY OF INFORMATION, NON-INFRINGEMENT AND TITLE. NO WARRANTY IS MADE
      THAT THE GAME WILL BE TIMELY, SECURE OR ERROR-FREE. In jurisdictions not
      allowing the limitation or exclusion of certain warranties, TIDEMILL
      GROUP’S warranty shall be limited to the greatest extent permitted by New
      York law.
    </p>
  </section>

  <section id="jurisdiction" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">
      Applicable Law; Jurisdiction and Venue
    </h2>
    <p>
      We control our Game from our offices within the United States. Any claim
      relating to our Game, Game Materials, or Content shall be governed by the
      laws of New York, without reference to its choice of law provisions. If
      there is a dispute between you and us, you expressly agree that exclusive
      jurisdiction and venue reside in the state and federal courts located in
      New York, New York.
    </p>
  </section>

  <section id="severability" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Severability</h2>
    <p>
      If any part of these TOU is determined to be invalid or unenforceable
      pursuant to applicable law including, but not limited to, the warranty
      disclaimers and the liability limitations set forth above, then the
      invalid or unenforceable provision will be deemed superseded by a valid,
      enforceable provision that most clearly matches the intent of the original
      provision and the remainder of these TOU shall continue in effect.
    </p>
  </section>

  <section id="assignment" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Assignment</h2>
    <p>
      TideMill Group is permitted to assign, transfer, and subcontract its
      rights and/or obligations under these TOU. However, you are not allowed to
      assign, transfer, or subcontract any of your rights and/or obligations
      under these TOU.
    </p>
  </section>

  <section id="dmca" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Digital Millennium Copyright Act</h2>
    <p>
      If you believe that any Game Materials or Content infringes your
      copyright, you (or your agent) may send us notice requesting that such
      Game Materials or Content be removed. Notice must be provided in writing
      and must include the following information:
    </p>
    <ul class="list-disc pl-6">
      <li>
        A signature of the person authorized to act on behalf of the owner of
        the copyright interest;
      </li>
      <li>
        Identification of the copyrighted work that you claim has been
        infringed;
      </li>
      <li>
        The location of the work you claim is infringing (e.g., URL) or enough
        detail that we may find it;
      </li>
      <li>Your name, address, telephone number, and e-mail address;</li>
      <li>
        A statement by you that you have a good faith belief that the disputed
        use is not authorized by the copyright owner, its agent, or the law; and
      </li>
      <li>
        A statement, made under penalty of perjury, that the information in your
        notice is accurate and that you are the copyright owner or authorized to
        act on the copyright owner's behalf.
      </li>
    </ul>
    <p>
      Notices must be sent to: Tidemill Group, 120 East End Ave, New York, NY
      10028.
    </p>
  </section>

  <section id="modifications" class="mb-6">
    <h2 class="text-2xl font-bold mb-2">Modifications</h2>
    <p>
      We may modify the Terms of Use at any time, in our sole discretion. In the
      event that we make changes, we will notify you and obtain your prior
      consent as required by law.
    </p>
  </section>

  <section id="contact" class="mb-6">
    <p>
      If you have any questions regarding these TOU, please contact us at
      <a href="mailto:info@tidemillgroup.com" class="text-blue-500"
        >info@tidemillgroup.com</a
      >.
    </p>
  </section>
</div>
